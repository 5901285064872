export const ActionPaths = {
	AddPatient: "add-patient",
	EditPatient: `edit-patient`,
	EditPatientPreselected: (id) => `edit-patient/${id}`,
	AddAppointment: "add-appointment",
	AddFullAppointment: "add-appointment/new",
	AddFollowUpAppointment: "add-appointment/follow-up",
	EditAppointment: "edit-appointment",
	EditAppointmentPreselected: (id) => `edit-appointment/${id}`,
	CancelAppointment: "cancel-appointment",
	CancelAppointmentPreselected: (id) => `cancel-appointment/${id}`,
	ComingSoon: "coming-soon",
	ReviewResults: (id) => `../orders/review-results/${id}`,
	FormHistoryResults: (id) => `form-history-results/${id}`,
	PrintModalClinic: "print/clinic",
	PrintModalSurgical: "print/surgical",
};

import { Ghost, NavLink } from "@streets-heaver/shui2";
import { Controller, useFormContext } from "react-hook-form";
import { Datebox } from "../../../../Datebox/Datebox";
import { Radio } from "../../../../Radio/Radio";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useGenderOptions } from "../../../../../api/hooks";
import { useEffect, useMemo } from "react";

export const Identity = ({ patientInfo }) => {
	const form = useFormContext();

	const genderOptionsQuery = useGenderOptions();
	const genderOptions = useMemo(
		() => genderOptionsQuery.data?.map((item) => ({ ...item, value: item.recordId })),
		[genderOptionsQuery.data],
	);

	const dateOfBirthName = "dateOfBirth";
	const genderName = "identity.gender";

	useEffect(() => {
		if (genderOptionsQuery?.data && patientInfo?.gender) {
			form.setValue(
				"gender",
				genderOptionsQuery.data.find((item) => item?.label === patientInfo?.gender),
			);
		}
	}, [form, genderOptionsQuery, patientInfo]);

	return (
		<div data-section id="identity">
			<FormGroup title="Identity">
				<FormField label="Date of birth" name={dateOfBirthName}>
					<Controller
						name={dateOfBirthName}
						render={({ field: { value, onChange } }) => (
							<Datebox
								customWidth={160}
								size="large"
								type={"filledDarker"}
								date={value}
								showInitialDate={typeof value !== "undefined"}
								onSelected={(e) => onChange(e)}
							/>
						)}
					/>
				</FormField>

				<FormField
					label="Gender (stated)"
					helperText={
						<>
							The patient&apos;s gender. This links to the protected (noneditable) Genders code table which is populated
							with values from the{" "}
							<NavLink
								to="https://www.datadictionary.nhs.uk/attributes/person_stated_gender_code.html"
								style={{ fontSize: "12px" }}
							>
								NHS data Dictionary
							</NavLink>
						</>
					}
					isMandatory
					name={genderName}
				>
					<Controller
						name={genderName}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Radio
								items={genderOptionsQuery.status === "success" ? genderOptions : radioGhostItems}
								value={value?.value}
								onChange={(e) => onChange(e)}
								disabled={genderOptionsQuery.status === "pending"}
								isError={error}
							/>
						)}
					/>
				</FormField>
			</FormGroup>
		</div>
	);
};

const radioGhostItems = [
	{ value: "loading-1", label: <Ghost width="16ch" height="1em" /> },
	{ value: "loading-2", label: <Ghost width="16ch" height="1em" /> },
	{ value: "loading-3", label: <Ghost width="16ch" height="1em" /> },
];

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./FilterButton.module.scss";
import { CompoundButton } from "@streets-heaver/shui2";

export const FilterButton = ({
	primaryText,
	secondaryText,
	icon,
	selectedIcon,
	toggled,
	toggleFilter,
	ghost,
	automationId,
}) => {
	return (
		<CompoundButton
			primaryText={primaryText}
			secondaryText={secondaryText}
			ghost={ghost}
			type="secondary"
			automationId={automationId}
			selected={toggled}
			onClick={toggleFilter}
			illustration={
				<div className={classes.icon}>
					{toggled ? (
						<FontAwesomeIcon
							icon={selectedIcon}
							style={{
								"--fa-primary-color": "var(--foregroundBrandOne)",
								"--fa-secondary-color": "var(--foregroundBrandOne)",
								color: "var(--foregroundBrandOne)",
							}}
						/>
					) : (
						<FontAwesomeIcon icon={icon} />
					)}
				</div>
			}
		/>
	);
};

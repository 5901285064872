import { Controller, useFormContext } from "react-hook-form";
import { NextOfKinField } from "../../../NextOfKinForm/NextOfKinField";
import { FormGroup } from "../../../FormGroup/FormGroup";

export const NextOfKin = () => {
	const form = useFormContext();

	const nextOfKinFieldName = "nextOfKin";

	return (
		<div data-section id="nextOfKin">
			<FormGroup>
				<Controller
					name={nextOfKinFieldName}
					control={form.control}
					render={({ field: { value, onChange } }) => <NextOfKinField onChange={onChange} data={value} />}
				/>
			</FormGroup>
		</div>
	);
};

import { useMutationState } from "@tanstack/react-query";
import { TruncatedItemList } from "../../../TruncatedItemList/TruncatedItemList";
import { Badge } from "@streets-heaver/shui2";

export const ServiceRequests = ({ requests, size = "larger" }) => {
	return (
		<TruncatedItemList
			items={requests.map((request, i) => (
				<Badge
					key={`request-${request?.serviceRequestName}-${i}`}
					text={request?.serviceRequestName}
					size={size}
					type={"tint"}
					color={"informative"}
				/>
			))}
			truncatedBadgeProps={{
				size: size,
				type: "tint",
				color: "informative",
				itemNameSingular: "request",
				itemNameMultiple: "requests",
			}}
		/>
	);
};

export const SeenRequest = ({ isRead, size = "medium" }) => {
	return isRead ? <Badge automationId="seen-badge" text={"Seen"} type={"tint"} size={size} color={"success"} /> : <></>;
};

export const PatientSeenRequest = ({ allSeen, orders }) => {
	const pendingOrders = useMutationState({
		filters: { mutationKey: ["markSeen"], status: "pending" },
		select: (mutation) => mutation.state.variables,
	}).flat();

	let allSeenOrPending = false;
	if (orders?.every((order) => order.status === "Seen" || order.status === "Sent")) {
		const sentOrders = orders?.filter((order) => order?.status === "Sent").map((order) => order?.orderId);

		allSeenOrPending = sentOrders.every((order) => pendingOrders.includes(order));
	}

	return <SeenRequest isRead={allSeen || allSeenOrPending} />;
};

import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import minMax from "dayjs/plugin/minMax";
import isBetween from "dayjs/plugin/isBetween";
import dayOfYear from "dayjs/plugin/dayOfYear";
import utc from "dayjs/esm/plugin/utc";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(advancedFormat);
dayjs.extend(minMax);
dayjs.extend(isBetween);
dayjs.extend(dayOfYear);
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

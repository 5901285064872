import ClinicianLogo from "../../images/logo.svg";
import "./LoadingSpinner.scss";

export const LoadingSpinner = () => {
	return (
		<div className="spinner">
			<img src={ClinicianLogo} alt="Clinician Logo" className="icon" />
		</div>
	);
};

import classes from "./Scheduler.module.scss";
import { useState } from "react";
import { Card } from "@streets-heaver/shui2";
import { PreviewPanel, ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import {
	AppointmentCalendar,
	LoadingSpinner,
	PatientCardPanel,
	SchedulerSettings,
	NoAccessPage,
} from "../../components";
import { Outlet } from "react-router";

export default function Scheduler() {
	const width = useContentSizeClass();
	const { currentClinician, userSecurityFlags } = useCurrentClinicians();
	const [dayRange, setDayRange] = useState(parseInt(localStorage.getItem("compucare.calendarDayRange")) || 7);
	const [startTime, setStartTime] = useState(parseInt(localStorage.getItem("compucare.calendarStartTime")) || 8);
	const [timescale, setTimescale] = useState(parseInt(localStorage.getItem("compucare.calendarInterval")) || 2);

	const [selectedBooking, setSelectedBooking] = useState(null);

	return (
		<div className={classes.scheduler} data-testid={"pageScheduler"}>
			<PreviewPanel
				onBackButtonClick={
					selectedBooking
						? () => {
								setSelectedBooking(null);
							}
						: undefined
				}
				title={selectedBooking && `${selectedBooking.type} booking`}
			>
				{selectedBooking && (
					<PatientCardPanel
						bookingType={selectedBooking.type}
						bookingUniqueId={selectedBooking.id}
						overrideTab={selectedBooking.overrideTab}
					/>
				)}
			</PreviewPanel>

			{!currentClinician ? (
				<LoadingSpinner />
			) : (
				<>
					{!userSecurityFlags.includes(`scheduler`) ? (
						<NoAccessPage />
					) : (
						<div className={classes.schedulerBlock}>
							<Outlet />
							{width < ScreenSize.TabletPortrait && (
								<div className={classes.schedulerSettings}>
									<SchedulerSettings
										timescale={timescale}
										setTimescale={setTimescale}
										startTime={startTime}
										setStartTime={setStartTime}
										dayRange={dayRange}
										setDayRange={setDayRange}
									/>
								</div>
							)}
							<Card className={classes.schedulerCard}>
								<AppointmentCalendar
									timescale={timescale}
									setTimescale={setTimescale}
									startTime={startTime}
									setStartTime={setStartTime}
									dayRange={dayRange}
									setDayRange={setDayRange}
									setSelectedBooking={setSelectedBooking}
									isSchedulerPage
								/>
							</Card>
						</div>
					)}
				</>
			)}
		</div>
	);
}

import { usePracticesLookup } from "../../../api/hooks";
import { useFormError } from "../../../utils";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { PracticeLookup } from "@streetsheaver/compucore";

export const PracticesLookup = ({ label = "Practice", name = "practice", isMandatory }) => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = usePracticesLookup(searchTerm);

	const error = useFormError(name);

	return (
		<FormField label={label} name={name} isMandatory={isMandatory}>
			<Controller
				name={name}
				control={form.control}
				render={({ field: { value, onChange, ref } }) => (
					<PracticeLookup
						selected={value}
                        onSearchTermChange={setSearchTerm}
						setSelected={onChange}
						searchQueryData={query}
						reference={ref}
						isError={error?.length > 0}
					/>
				)}
			/>
		</FormField>
	);
};
import { FormWrapper } from "../../components";

export const ComingSoon = () => {
	return (
		<FormWrapper header={"Coming Soon"}>
			<p style={{ textAlign: "center" }}>
				The content that will be here is currently under development. Check back later!
			</p>
		</FormWrapper>
	);
};

import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useCurrentClinicians } from "../useClinicians";
import { useDataSources } from "@streetsheaver/compucore";

export const useEpisodeOfCaresLookup = (patientId, take = 10) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const episodeOfCaresQuery = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "episodeOfCares", currentClinician, patientId, take],
		queryFn: async ({ pageParam }) => {
			const data = await getEpisodeOfCares(
				currentDataSource?.DataSourceId,
				currentClinician,
				patientId,
				pageParam,
				take,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && !isNaN(take),
	});
	return {
		...episodeOfCaresQuery,
		data: episodeOfCaresQuery?.data?.pages?.flatMap((page) =>
			page?.data?.map((result, index) => {
				return { ...result, episodeOfCareId: index };
			}),
		),
	};
};

const getEpisodeOfCares = async (dataSourceId, clinicianId, patientId, skip = 0, take) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/lookup/eoc?patientId=${patientId}&skip=${skip}&take=${take + 1}`,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};

export const timeOptions = (calendarStartTime, setCalendarStartTime) => {
	let timeOptions = [...Array(24)].map((val, time) => {
		return {
			text: `${time}:00`,
			isChecked: calendarStartTime === time,
			onClick: () => {
				setCalendarStartTime(time);
				localStorage.setItem("compucare.calendarStartTime", time);
			},
		};
	});
	return timeOptions;
};

export const intervalOptions = (calendarInterval, setCalendarInterval) => {
	let options = [5, 10, 15, 30, 60];
	let intervalOptions = options.map((value) => {
		return {
			text: `${value} minutes`,
			isChecked: calendarInterval === 60 / value,
			onClick: () => {
				setCalendarInterval(60 / value);
				localStorage.setItem("compucare.calendarInterval", 60 / value);
			},
		};
	});

	return intervalOptions;
};

import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsalAuthentication } from "@azure/msal-react";
import Routes from "./layouts/routes";
import { InteractionType } from "@azure/msal-browser";
import { CompucoreProvider } from "@streetsheaver/compucore";

export default function App() {
	useMsalAuthentication(InteractionType.Redirect);
	return (
		<>
			<AuthenticatedTemplate>
				<CompucoreProvider tenantsApiUrl={import.meta.env.VITE_TENANTS_API_URL}>
					<Routes />
				</CompucoreProvider>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<div>You are currently not signed in. Please sign in.</div>
			</UnauthenticatedTemplate>
		</>
	);
}

import classes from "./NoAccessPage.module.scss";
import { Card } from "@streets-heaver/shui2";
import { NoAccessMessage } from "../../components"

export const NoAccessPage = () => {
	return (
		<div className={classes.noAccessPage}>
			<Card className={classes.noAccessPageCard}>
				<NoAccessMessage />
			</Card>
		</div>
	);
};

import classes from "./InfoRow.module.scss";
import { Ghost } from "@streets-heaver/shui2";
import clsx from "clsx";

export const InfoRow = ({ icon, info, ghost = false, hide = false, colour = "one", iconSize = "medium", style }) => {
	if (hide) return <></>;
	return ghost ? (
		<div className={classes.InfoRow}>
			{icon && <Ghost height="16px" width="16px" borderRadius="999px" />}
			{info && <Ghost height="20px" width="200px" borderRadius="4px" />}
		</div>
	) : (
		<div className={clsx(classes.InfoRow, classes[colour])} style={style}>
			{icon && <div className={clsx(classes.Icon, classes[iconSize])}>{icon}</div>}
			{info && <div className={classes.Info}>{info}</div>}
		</div>
	);
};

import { ServiceRequestRow } from "../ServiceRequestRow/ServiceRequestRow";
import classes from "./ExpandingOrderRow.module.scss";
import { ActionPaths } from "../../../../layouts/Layout/actionPaths";

export const ExpandingOrderRow = ({ ...row }) => {
	return (
		<div className={classes.ordersContainer}>
			{row?.original?.orders?.map((order, orderIndex) => (
				<ServiceRequestRow
					key={`${row?.original?.orderUniqueId}-${orderIndex}`}
					request={order}
					resultCallback={() => ActionPaths.ReviewResults(row?.original?.patientUniqueId)}
				/>
			))}
		</div>
	);
};

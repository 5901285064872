import classes from "./GroupBookingCard.module.scss";
import { useState } from "react";
import { PatientCardNextPatient } from "../PatientCard/PatientCard";
import { AppointmentBody } from "../AppointmentBody/AppointmentBody";
import { Button } from "@streets-heaver/shui2";
import { faChevronLeft, faChevronRight } from "@shfortawesome/pro-light-svg-icons";

export const GroupBookingCard = ({ groupBookingInfo, noPatientTabs }) => {
	const [page, setPage] = useState(0);
	return (
		<div className={classes.GroupBookingCard}>
			<AppointmentBody customHeader={"Group booking details"} appointment={groupBookingInfo} />
			<div className={classes.Patients}>
				<p className={classes.PatientTitle}>Patients</p>
				<div className={classes.PatientNav}>
					<Button
						type="transparent"
						onClick={() => {
							setPage(page - 1);
						}}
						icon={{ restIcon: faChevronLeft }}
						disabled={page < 1}
					/>
					<p>
						Group Booking {page + 1}
						{groupBookingInfo?.data?.groupBookingIds?.length
							? ` of ${groupBookingInfo?.data?.groupBookingIds?.length}`
							: ""}
					</p>
					<Button
						type="transparent"
						onClick={() => {
							setPage(page + 1);
						}}
						icon={{ restIcon: faChevronRight }}
						disabled={page >= groupBookingInfo?.data?.groupBookingIds?.length - 1}
					/>
				</div>
				<PatientCardNextPatient
					bookingUniqueId={groupBookingInfo?.data?.groupBookingIds?.[page]}
					roundedHeader
					noTabs={noPatientTabs}
				/>
			</div>
		</div>
	);
};

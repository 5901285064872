import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";

export const useOrderResults = (patientId, take) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const query = useInfiniteQuery({
		queryKey: ["orderResults", currentClinician, patientId, take],
		initialPageParam: 0,
		queryFn: async ({pageParam}) => {
			return await getOrderResults(currentDataSource?.DataSourceId, currentClinician, patientId, pageParam, take);
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && !!patientId && !isNaN(take),
	});
	return query;
};

const getOrderResults = async (dataSourceId, clinicianId, patientId, skip = 0, take) => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/orders/patient/${patientId}/results?skip=${skip}&take=${take + 1}`, {}, "v2",
	);
	if (data?.request?.response) throw new Error("Getting order results failed");
	return {
		data: { ...data, serviceRequests: data.serviceRequests.slice(0, take) },
		nextPageIndex: data?.serviceRequests?.length >= take + 1 ? skip + take : undefined,
	};
};

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "./useClinicians";
import toast from "react-hot-toast";

export const useMarkAsSeen = () => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const queryClient = useQueryClient();

	return useMutation({
		mutationKey: ["markSeen"],
		mutationFn: (orderId) => {
			const idArray = orderId?.length ? orderId : [orderId];
			return makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/order/seen?${idArray
					.map((id) => `orderId=${id}`)
					.join("&")}`,
				{
					method: "put",
				},
			);
		},
		onSettled: async () => {
			return await queryClient.invalidateQueries({ queryKey: ["orders"] });
		},
		onError: () =>
			toast.error({
				title: "Error marking as seen",
				subtitle: `Failed to mark order as seen.`,
			}),
	});
};

import classes from "./EpisodeOfCareCard.module.scss";
import { Ghost, Progress } from "@streets-heaver/shui2";
import { getEocStatusColour, shortDateFormat, getBreachPercentage } from "../../utils";
import dayjs from "dayjs";

export const EpisodeOfCareCard = ({ data, ghost, onClick, automationId }) => {
	const nowValue = getBreachPercentage(data?.eocStartDate, data?.eocBreachDate);
	const hasBreached = dayjs(data?.eocBreachDate).isSameOrBefore(dayjs());

	const ghostLine = (
		<div style={{ margin: "2px 0" }}>
			<Ghost height={"10px"} width={"120px"} borderRadius={"4px"} />
		</div>
	);

	return (
		<div className={classes.EOCListItem} onClick={onClick} data-testid={automationId}>
			<div className={classes.EOCHeader}>
				<div className={classes.Status} style={{ backgroundColor: getEocStatusColour(data?.eocIsOpen) }} />
				<div className={classes.Details}>
					<div>
						{ghost ? ghostLine : <p>Referred: {data?.eocReferralDate ? shortDateFormat(data.eocReferralDate) : "-"}</p>}
						{ghost ? (
							ghostLine
						) : (
							<p>{data?.eocDiagnosis ? `Diagnosis: ${data.eocDiagnosis}` : "No presenting diagnosis"}</p>
						)}
						{ghost ? ghostLine : <p>{data?.eocReferrer ? `Referrer: ${data.eocReferrer}` : "No referrer"}</p>}
						{ghost ? ghostLine : <p>{data?.eocClinician ? `Case holder: ${data.eocClinician}` : "No case holder"}</p>}
					</div>
					<div>
						{ghost ? ghostLine : <p>Started: {data?.eocStartDate ? shortDateFormat(data.eocStartDate) : "-"}</p>}
						{ghost ? ghostLine : <p>RTT Status: {data?.eocRttStatus ? data.eocRttStatus : "N/A"}</p>}
						{ghost ? ghostLine : <p>{data?.eocSource ? `Source: ${data.eocSource}` : "No source"}</p>}
						{ghost ? ghostLine : <p>{data?.eocSpeciality ? `Specialty: ${data.eocSpeciality}` : "No specialty"}</p>}
					</div>
				</div>
			</div>
			{data?.eocBreachDate && (
				<div className={classes.BreachContainer}>
					<p>{`Breach date: ${shortDateFormat(data.eocBreachDate)}`}</p>
					<div className={classes.BreachProgression}>
						<div className={classes.TitleBar}>
							<p>Started</p>
							<p>Breached</p>
						</div>
						<BreachProgression />
						<Progress size="large" max={100} value={nowValue} status={hasBreached ? "danger" : "default"} />
					</div>
				</div>
			)}
		</div>
	);
};

const BreachProgression = () => {
	return (
		<div className={classes.BreachProgressBar}>
			<div className={classes.BreachWarning} />
			<div className={classes.BreachDanger} />
		</div>
	);
};

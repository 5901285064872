import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./DateButton.module.scss";
import { Calendar } from "@streets-heaver/shui2";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import { faChevronDown } from "@shfortawesome/pro-regular-svg-icons";
import { useState, useRef } from "react";
import { useOutsideAlerter } from "../../utils/hooks/useOutsideAlerter";
import { longDateFormat, shortDateFormat } from "../../utils/dateFormatting";
import { clsx } from "clsx";

export const DateButton = ({ enabled, calendarProps }) => {
	const width = useContentSizeClass();
	const [open, setOpen] = useState(false);
	const parentRef = useRef();

	useOutsideAlerter(parentRef, () => setOpen(false));

	function handleSelected(e) {
		calendarProps.onSelected && calendarProps.onSelected(e);
		setOpen(false);
	}

	return (
		<div className={classes.dateButton} ref={parentRef}>
			<div
				className={clsx(classes.button, enabled && classes.enabled, open && classes.open)}
				onClick={() => setOpen((prev) => !prev)}
				data-testid="date-button"
			>
				<div className={classes.buttonContent}>
					<p>
						{width < ScreenSize.TabletPortrait
							? shortDateFormat(calendarProps.date)
							: longDateFormat(calendarProps.date)}
					</p>
					{enabled && <FontAwesomeIcon icon={faChevronDown} />}
				</div>
			</div>
			{enabled && (
				<div className={classes.calendar}>
					<Calendar visible={open} {...calendarProps} onSelected={handleSelected} automationId="date-button-calendar" />
				</div>
			)}
		</div>
	);
};

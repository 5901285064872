import dayjs from "dayjs";

export const longDateFormat = (date) => {
	const newDate = dayjs(date);
	return newDate.format("Do MMMM YYYY");
};

export const shortDateFormat = (date) => {
	const newDate = dayjs(date);
	return newDate.format("DD/MM/YYYY");
};

export const timeFormat = (date) => {
	const newDate = dayjs(date);
	return newDate.format("HH:mm");
};

export const dateTimeFormat = (datetime) => {
	const newDateTime = dayjs(datetime);
	return newDateTime.format("DD/MM/YYYY, HH:mm");
};

export const localFromUTC = (date) => {
	return dayjs.utc(date).local();
};

export const localToUTC = (date) => {
	return dayjs.utc(date);
};

export const ageFromDate = (date) => {
	const now = dayjs();
	const dob = dayjs(date);
	const yearDiff = now.diff(dob, "year");
	if (yearDiff > 0) {
		return yearDiff + "y";
	}
	const monthDiff = now.diff(dob, "month");
	if (monthDiff > 0) {
		return monthDiff + "m";
	}
	const weekDiff = now.diff(dob, "week");
	if (weekDiff > 0) {
		return weekDiff + "w";
	}
	const dayDiff = now.diff(dob, "day");
	return dayDiff + "d";
};

export const durationFromDates = (from, to) => {
	const diff = dayjs(to).diff(from, "minute");
	if (diff >= 60) {
		let duration = dayjs(to).diff(from, "hour") + "h";
		if (diff % 60) {
			duration += ` ${diff % 60}m`;
		}

		return duration;
	}
	return diff + "m";
};

export const nightsBetweenDates = (from, to) => {
	const dayFrom = dayjs(from);
	const dayTo = dayjs(to);

	const dayDiff = dayTo.diff(dayFrom, "day");
	return `${dayDiff === 0 ? "Daycase" : dayDiff === 1 ? "1 night" : `${dayDiff} nights`} until ${to.format("HH:mm")}`;
};

import { usePreviewPanelBooking } from "../../../api/hooks/usePreviewPanelBooking";
import { PatientCardPreviewPanel } from "../../PatientCard/PatientCard";

export const PatientCardPanel = ({ bookingType, bookingUniqueId, overrideTab }) => {
	const previewPanelBooking = usePreviewPanelBooking(bookingType.toLowerCase(), bookingUniqueId);

	return (
		<PatientCardPreviewPanel
			type={bookingType}
			ghost={previewPanelBooking.isLoading}
			appointment={previewPanelBooking.data}
			overrideTab={overrideTab}
			automationId="previewpanel-patientcard"
		/>
	);
};

import { dateTimeFormat, durationFromDates, timeFormat, localFromUTC } from "../../../../utils";
import { TruncatedArray } from "../../../TruncatedArray/TruncatedArray";
import classes from "./ExpandingMyPatientsRow.module.scss";

export const getExpandingRow = (type) => {
	switch (type) {
		default:
		case "outpatient":
			return ExpandingMyPatientsOutpatientRow;
		case "inpatient":
			return ExpandingMyPatientsInpatientRow;
		case "surgical":
			return ExpandingMyPatientsSurgicalRow;
	}
};

const ExpandingMyPatientsOutpatientRow = ({ ...row }) => {
	return (
		<div className={classes.myPatientsRowContainer}>
			<LocationRow location={row?.original?.location} />
			<TimeRow start={row?.original?.start} end={row?.original?.end} />
			<ContactTypeRow contactType={row?.original?.contactType} />
		</div>
	);
};

const ExpandingMyPatientsInpatientRow = ({ ...row }) => {
	return (
		<div className={classes.myPatientsRowContainer}>
			<AdmissionRow start={row?.original?.start} />
			<DischargeRow end={row?.original?.end} />
			<ProceduresRow procedures={row?.original?.procedures} />
			<BookingStatusRow status={row?.original?.status} />
		</div>
	);
};

const ExpandingMyPatientsSurgicalRow = ({ ...row }) => {
	return (
		<div className={classes.myPatientsRowContainer}>
			<TimeRow start={row?.original?.start} end={row?.original?.end} />
			<ProceduresRow procedures={row?.original?.procedures} />
		</div>
	);
};

const TimeRow = ({ start, end }) => {
	let newStart = localFromUTC(start);
	let newEnd = localFromUTC(end);
	return (
		<>
			<p className={classes.header}>Time</p>
			<p className={classes.body}>{`${timeFormat(newStart)} - ${timeFormat(newEnd)}, ${durationFromDates(
				newStart,
				newEnd,
			)}`}</p>
		</>
	);
};

const LocationRow = ({ location }) => (
	<>
		<p className={classes.header}>Location</p>
		<p className={classes.body}>{location ?? "-"}</p>
	</>
);

const ContactTypeRow = ({ contactType }) => (
	<>
		<p className={classes.header}>Contact type</p>
		<p className={classes.body}>{contactType ?? "Face to face"}</p>
	</>
);

const AdmissionRow = ({ start }) => (
	<>
		<p className={classes.header}>Admission</p>
		<p className={classes.body}>{dateTimeFormat(localFromUTC(start))}</p>
	</>
);

const DischargeRow = ({ end }) => (
	<>
		<p className={classes.header}>Discharge</p>
		<p className={classes.body}>{dateTimeFormat(localFromUTC(end))}</p>
	</>
);

const ProceduresRow = ({ procedures }) => (
	<>
		<p className={classes.header}>Procedure(s)</p>
		<TruncatedArray list={procedures} name="procedures" />
	</>
);

const BookingStatusRow = ({ status }) => (
	<>
		<p className={classes.header}>Booking status</p>
		<p className={classes.body}>{status ?? "-"}</p>
	</>
);

import { RouterProvider, useRouteError } from "react-router-dom";
import { createDataSourceBrowserRouter, Toolbar } from "@streetsheaver/compucore";
import Dashboard from "../pages/Dashboard/Dashboard";
import Layout from "./Layout/Layout";
import ErrorPage from "../pages/ErrorPage/ErrorPage";
import Scheduler from "../pages/Scheduler/Scheduler";
import MyPatients from "../pages/MyPatients/MyPatients";
import Orders from "../pages/Orders/Orders";
import PatientView from "../pages/PatientView/PatientView";
import { AddAppointment } from "../pages/AddAppointment/AddAppointment";
import { EditAppointment } from "../pages/EditAppointment/EditAppointment";
import { CancelAppointment } from "../pages/CancelAppointment/CancelAppointment";
import { AddPatient } from "../pages/AddPatient/AddPatient";
import { EditPatient } from "../pages/EditPatient/EditPatient";
import { ComingSoon } from "../pages/ComingSoon/ComingSoon";
import { ActionPaths } from "./Layout/actionPaths";
import { AppointmentTypeModal, FormResultsHistoryModal, PrintModal } from "../modals";
import { Page } from "./Layout/Page";

function ErrorBoundary() {
	let error = useRouteError();
	return <ErrorPage error={error} />;
}

const universalModals = [
	{ path: ActionPaths.AddAppointment, element: <AppointmentTypeModal /> },
	{ path: ActionPaths.PrintModalClinic, element: <PrintModal type="clinic" /> },
	{ path: ActionPaths.PrintModalSurgical, element: <PrintModal type="surgical" /> },
];

const router = createDataSourceBrowserRouter(
	{
		routes: [
			{
				path: "",
				element: <Layout />,
				children: [
					{
						path: "",
						element: <Page />,
						children: [
							{
								path: "",
								element: <Dashboard />,
								children: universalModals,
							},
							{
								path: "scheduler",
								element: <Scheduler />,
								children: universalModals,
							},
							{
								path: "mypatients",
								element: <MyPatients />,
								children: universalModals,
							},
							{
								path: "orders/review-results?/:patientId?",
								element: <Orders />,
								children: [
									...universalModals,
									{ path: ActionPaths.FormHistoryResults(":formComponentId"), element: <FormResultsHistoryModal /> },
								],
							},
							{
								path: "patient/:patientId",
								element: <PatientView />,
								children: universalModals,
							},
							{
								path: ActionPaths.AddPatient,
								element: <AddPatient />,
								children: universalModals,
							},
							{
								path: ActionPaths.EditPatient,
								element: <EditPatient />,
								children: universalModals,
							},
							{
								path: ActionPaths.EditPatientPreselected(":patientId"),
								element: <EditPatient />,
								children: universalModals,
							},
							{
								path: ActionPaths.AddFullAppointment,
								element: <AddAppointment type="full" />,
								children: universalModals,
							},
							{
								path: ActionPaths.AddFollowUpAppointment,
								element: <AddAppointment type="follow-up" />,
								children: universalModals,
							},
							{
								path: ActionPaths.EditAppointmentPreselected(":bookingId?"),
								element: <EditAppointment />,
								children: universalModals,
							},
							{
								path: ActionPaths.CancelAppointmentPreselected(":bookingId?"),
								element: <CancelAppointment />,
								children: universalModals,
							},
							{
								path: ActionPaths.ComingSoon,
								element: <ComingSoon />,
								children: universalModals,
							},
						],
					},
				],
			},
		],
		options: {
			basename: import.meta.env.BASE_URL,
		},
	},
	{ errorElement: <ErrorBoundary /> },
);

export default function Routes() {
	return <RouterProvider router={router} />;
}

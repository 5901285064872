import { z } from "zod";
import dayjs from "dayjs";

export const patientLookupSchema = z.object(
	{ patientId: z.union([z.number(), z.string()]) },
	{ message: "A patient must be selected" },
);

const lookupSchema = z.nullable(z.object({ recordId: z.union([z.number(), z.string()]) })).optional();

const referralDateSchema = z
	.date()
	.refine((value) => !dayjs(value).isAfter(dayjs(), "day"), { message: "Referral date must be in the past." })
	.optional();

export const newPatientSchema = {
	patient: patientLookupSchema,
	referralDate: referralDateSchema,
	referrer: lookupSchema,
	caseHolder: lookupSchema,
	diagnosis: lookupSchema,
};

import { z } from "zod";

const addressLine1Schema = z.string().max(72, { message: "Address Line 1 must be 72 characters or less" }).optional();
const addressLine2Schema = z.string().max(50, { message: "Address Line 2 must be 50 characters or less" }).optional();
const addressLine3Schema = z.string().max(50, { message: "Address Line 3 must be 50 characters or less" }).optional();
const townCitySchema = z.string().max(50, { message: "Town/City must be 50 characters or less" }).optional();
const countySchema = z.string().max(50, { message: "County must be 50 characters or less" }).optional();
const postCodeSchema = z
	.string()
	.regex(/[A-Za-z]{1,2}[0-9Rr][0-9A-Za-z]? [0-9][ABD-HJLNP-UW-Zabd-hjlnp-uw-z]{2}/, { message: "Invalid postcode" })
	.or(z.string().length(0))
	.optional();

export const emailSchema = z.union([
	z.string().email().max(100, { message: "Email must be 100 characters or less " }),
	z.string().length(0),
]);
export const mobilePhoneSchema = z.nullable(
	z
		.string()
		.regex(/^([+0])([0-9 +*()-]){7,25}/, { message: "Invalid mobile phone number" })
		.or(z.string().length(0))
		.optional(),
);
export const workPhoneSchema = z.nullable(
	z
		.string()
		.regex(/^([+0])([0-9 +*()-]){7,25}/, { message: "Invalid work phone number" })
		.or(z.string().length(0))
		.optional(),
);
export const mainPhoneSchema = z.nullable(
	z
		.string()
		.regex(/^([+0])([0-9 +*()-]){7,25}/, { message: "Invalid main phone number" })
		.or(z.string().length(0))
		.optional(),
);

export const addressSchema = z.object({
	addressLine1: addressLine1Schema,
	addressLine2: addressLine2Schema,
	addressLine3: addressLine3Schema,
	addressLine4: townCitySchema,
	addressLine5: countySchema,
	postCode: postCodeSchema,
	country: z.nullable(z.object({ recordId: z.union([z.number(), z.string()]) })).optional(),
});

export const contactMethodSchema = {
	email: emailSchema,
	mobilePhone: mobilePhoneSchema,
	workPhone: workPhoneSchema,
	mainPhone: mainPhoneSchema,
};

export const contactSchema = {
	address: addressSchema,
	...contactMethodSchema,
	notificationMethodConfirmed: z.boolean(),
	preferredNotificationMethod: z.nullable(
		z.discriminatedUnion("value", [
			z.object({ value: z.literal("1") }),
			z.object({ value: z.literal("2") }),
			z.object({ value: z.literal("3") }),
			z.object({ value: z.literal("4") }),
		]),
	),
	preferredWrittenMethod: z.string(),
};

export const preferredNotificationMethodRefinement = {
	condition: (val) => !(val.notificationMethodConfirmed && val.preferredNotificationMethod === null),
	args: {
		message: "Preferred notification method is required. Please choose an option from the list. ",
		path: ["preferredNotificationMethod"],
	},
};

export const emailRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			(val.preferredWrittenMethod === "email" ||
				(val.preferredNotificationMethod?.value === "email" && val.notificationMethodConfirmed === true)) &&
			val.email === ""
		),
	args: {
		message: "Email is required when it is chosen as the preferred notification / written method.",
		path: ["email"],
	},
};

export const addressLine1RequiredWrittenMethodRefinement = {
	condition: (val) => !(val.preferredWrittenMethod === "post" && val.address.addressLine1 === ""),
	args: {
		message: "Address Line 1 is required when post is chosen as the preferred written method.",
		path: ["address", "addressLine1"],
	},
};

export const postCodeRequiredWrittenMethodRefinement = {
	condition: (val) => !(val.preferredWrittenMethod === "post" && val.address.postCode === ""),
	args: {
		message: "Postcode is required when post is chosen as the preferred written method.",
		path: ["address", "postCode"],
	},
};

export const mobilePhoneRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.preferredNotificationMethod?.value === "mobile" &&
			val.notificationMethodConfirmed === true &&
			(val.mobilePhone === "" || val.mobilePhone === null)
		),
	args: {
		message: "Mobile phone is required when it is chosen as the preferred notification method.",
		path: ["mobilePhone"],
	},
};

export const mainPhoneRequiredNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.preferredNotificationMethod?.value === "sms-to-landline" &&
			val.notificationMethodConfirmed === true &&
			(val.mainPhone === "" || val.mainPhone === null)
		),
	args: {
		message: "Main phone is required when SMS to main phone is chosen as the preferred notification method.",
		path: ["mainPhone"],
	},
};

export const mobilePhoneRequiredSMSNotificationMethodRefinement = {
	condition: (val) =>
		!(
			val.preferredNotificationMethod?.value === "sms-to-mobile" &&
			val.notificationMethodConfirmed === true &&
			(val.mobilePhone === "" || val.mobilePhone === null)
		),
	args: {
		message: "Mobile phone is required when SMS to mobile is chosen as the preferred notification method.",
		path: ["mobilePhone"],
	},
};

import { useCurrentClinicians } from "../../api/hooks/useClinicians";

export const usePageError = () => {
	const { errorClinicians, loadingClinicians, clinicians } = useCurrentClinicians();

	if (errorClinicians) return errorClinicians?.response?.data;

	if (!loadingClinicians && clinicians?.length < 1)
		return {
			title: "No clinicians found",
			message: "This user account has no clinicians assigned against it. Please contact an administrator.",
		};

	return undefined;
};

import { get, useFormState } from "react-hook-form";

export const useFormError = (name) => {
	const { errors } = useFormState({ name });
	const fieldErrors = get(errors, name);

	if (!fieldErrors) {
		return [];
	}
	if ("root" in fieldErrors) {
		return collectErrors(fieldErrors.root);
	}

	return collectErrors(fieldErrors);
};

const collectErrors = (errors) => {
	if ("message" in errors || "type" in errors) {
		return getErrorList(errors);
	}
	const values = Object.values(errors);

	if (values.length === 0) {
		return [];
	}

	return values.map(collectErrors).flatMap((e) => e);
};

const getErrorList = (errors) => {
	const { message, types } = errors;

	if (types) {
		return Object.values(types).flatMap((e) => e);
	}

	if (message) {
		return [message];
	}

	return [];
};

import { useInfiniteQuery } from "@tanstack/react-query";

export const useRegisteredGPs = (searchTerm) => {
	const resultsQuery = useInfiniteQuery({
		queryKey: ["registeredGPs", searchTerm],
		queryFn: ({ pageParam = 0 }) =>
			getRegisteredGPs({
				offset: pageParam,
				search: searchTerm,
			}),
		getNextPageParam: (lastPage, _allPages, lastPageParam = 0) => {
			return lastPage.hasNextPage ? lastPageParam + lastPage.results.length : undefined;
		},
	});

	return {
		...resultsQuery,
		data: resultsQuery?.data?.pages?.flatMap((page) => page.results),
	};
};

const getRegisteredGPs = async ({ search, offset }) => {
	await new Promise((resolve) => setTimeout(resolve, 1500));
	return simpleSearch({ data: GP_DATA, offset, query: search, isLike });
};

const isLike = (gp, query) => gp.label.toLowerCase().includes(query.toLowerCase());

const simpleSearch = ({ data, isLike, pageSize = 10, defaultQuery = "", query, offset, count }) => {
	const queryParam = query ?? defaultQuery;
	const offsetParam = offset ?? 0;
	const countParam = Math.min(count ?? pageSize, pageSize);

	const results = data.filter((item) => isLike(item, queryParam));
	const resultsPage = results.slice(offsetParam, offsetParam + countParam);

	return { results: resultsPage, hasNextPage: offsetParam + countParam < results.length };
};

const GP_DATA = [
	{ id: 1, label: "SURNAME, Forename (Dr)" },
	{ id: 2, label: "SURNAME, Forename (Mr)" },
	{ id: 3, label: "SURNAME, Forename (Miss)" },
	{ id: 4, label: "SURNAME, Forename (Mx)" },
	{ id: 5, label: "SURNAME, Forename (Mrs)" },
];

import { useOutcomesLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const OutcomesLookup = () => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useOutcomesLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={"Outcome"}
			lookupName={"outcome"}
			setSearchTerm={setSearchTerm}
			query={query}
		/>
	);
};

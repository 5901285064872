export const bookingStatus = {
	Due: [0, 1],
	Waiting: [102],
	"In progress": [2],
	Completed: [3],
	"Cancelled/DNA": [100, 101],
};

export const getStatusTextUpdate = (status) => {
	switch (status?.toLowerCase()) {
		default:
		case "unknown":
			return "Status is unknown";
		case "booked":
			return "Patient is due";
		case "arrived":
			return "Patient has arrived";
		case "attended":
			return "Appointment in progress";
		case "completed":
			return "Appointment completed";
		case "cancelled":
			return "Appointment cancelled";
		case "dna":
			return "Patient did not attend";
	}
};

export const PatientDummyData = [
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1974-08-05T11:13:14.139590"),
		addressLine1: "78 Clyde Gallagher Hill",
		patientId: "6949866481",
		patientPrimaryId: "323498",
		forename: "Jasmine",
		surname: "Burton",
		mobilePhone: "",
		mainPhone: "01234 567899",
		workPhone: "",
		registeredGP: { id: 3, label: "SURNAME, Forename (Miss)" },
		preferredNotificationMethod: { value: "email", label: "Email" },
		nextOfKin: [
			{
				title: "Miss",
				forename: "Molly",
				surname: "McCarthy",
				email: "",
				phone: "",
				mobile: "",
				address: {
					lineOne: "475 Bay Crossing",
					lineTwo: "",
					lineThree: "",
					townCity: "",
					county: "",
					postcode: "",
				},
				dateOfBirth: new Date("1953-09-24T23:00:00.000Z"),
				relationship: {
					relationshipId: 4,
					label: "Sister",
				},
			},
			{
				title: "Mr",
				forename: "Evan",
				surname: "Peters",
				email: "",
				phone: "",
				mobile: "",
				address: {
					lineOne: "14 Bay Crossing",
					lineTwo: "",
					lineThree: "",
					townCity: "",
					county: "",
					postcode: "",
				},
				dateOfBirth: new Date("1988-01-04T23:00:00.000Z"),
				relationship: {
					relationshipId: 1,
					label: "Father",
				},
			},
		],
	},
	{
		gender: "Male",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1947-01-04T11:13:14.139590"),
		addressLine1: "5916 Huxley Point",
		patientId: "5462499207",
		patientPrimaryId: "323435",
		forename: "Jimmy",
		surname: "Clark",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: true,
		dateOfBirth: new Date("1987-05-16T11:13:14.139590"),
		addressLine1: "475 Bay Crossing",
		patientId: "9290800750",
		patientPrimaryId: "323457",
		forename: "Lois",
		surname: "Bell",
	},
	{
		gender: "Male",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1978-10-16T11:13:14.139590"),
		addressLine1: "74 Brickson Park Terrace",
		patientId: "2516818290",
		patientPrimaryId: "323504",
		forename: "Jackson",
		surname: "Burke",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1974-09-08T11:13:14.139590"),
		addressLine1: "78 Clyde Gallagher Hill",
		patientId: "4718462661",
		patientPrimaryId: "323487",
		forename: "Lottie",
		surname: "Hughes",
	},
	{
		gender: "Male",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("2007-12-17T11:13:14.139590"),
		addressLine1: "88 Tennyson Avenue",
		patientId: "6217397452",
		patientPrimaryId: "323507",
		forename: "Riley",
		surname: "Gill",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: true,
		dateOfBirth: new Date("1953-09-25T11:13:14.139590"),
		addressLine1: "475 Bay Crossing",
		patientId: "6030704375",
		patientPrimaryId: "323521",

		forename: "Molly",
		surname: "Mccarthy",
	},
	{
		gender: "Male",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1971-08-19T11:13:14.139590"),
		addressLine1: "81 Springs Trail",
		patientId: "8583111274",
		patientPrimaryId: "323479",

		forename: "Reggie",
		surname: "Lawson",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1991-07-03T11:13:14.139590"),
		addressLine1: "5916 Huxley Point",
		patientId: "8423340009",
		patientPrimaryId: "323512",

		forename: "Erica",
		surname: "Davidson",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: true,
		dateOfBirth: new Date("1982-11-13T11:13:14.139590"),
		addressLine1: "5916 Huxley Point",
		patientId: "1019443262",
		patientPrimaryId: "323490",

		forename: "Amelia",
		surname: "Davis",
	},
	{
		gender: "Male",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("1971-05-31T11:13:14.139590"),
		addressLine1: "842 Debs Place",
		patientId: "9470664772",
		patientPrimaryId: "323431",

		forename: "Cian",
		surname: "Gardner",
	},
	{
		gender: "Female",
		pictureUrl: null,
		patientPortalReg: false,
		dateOfBirth: new Date("2000-01-08T11:13:14.139590"),
		addressLine1: "0 Armistice Court",
		patientId: "7309421554",
		patientPrimaryId: "323511",

		forename: "Iris",
		surname: "Bates",
	},
];

import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { makeRequest } from "./makeRequest";
import { msalInstance } from "../authConfig";

const apiInstance = axios.create({ baseURL: `${import.meta.env.VITE_TENANTS_API_URL}/clinicianportal` });

export const makeApiRequest = async (url, { method = "get", params, headers, body } = {}, version = "v1") => {
	const token = await acquireMsalToken(msalInstance, ["api://93c2c2b7-62a3-44a7-9a44-37ad6e6712e4/Datasources.Read"]);
	return makeApiRequestTokenless(`/${version}/datasource/${url}`, {
		method,
		params,
		headers: { ...headers, "Content-Type": "application/json", Authorization: `Bearer ${token}` },
		body,
	});
};

export const makeApiRequestTokenless = (url, { method = "get", params, headers, body } = {}) => {
	return makeRequest(url, { method, params, headers, body, axiosInstance: apiInstance });
};

export const acquireMsalToken = async (msalInstance, customScopes) => {
	await msalInstance.initialize();

	const accounts = msalInstance.getAllAccounts();
	const response = await msalInstance
		.acquireTokenSilent({
			scopes: customScopes,
			account: accounts[0],
		})
		.catch(async function (error) {
			if (error instanceof InteractionRequiredAuthError && error.errorCode !== "invalid_grant") {
				const response = await msalInstance.acquireTokenRedirect({
					scopes: customScopes,
					account: accounts[0],
				});
				return response;
			} else {
				throw error;
			}
		});
	return response?.accessToken;
};

import classes from "./TableTitle.module.scss";

export const TableTitle = ({ details }) => {
	return (
		<div className={classes.TableTitle}>
			<div className={classes.TableTitleLeft}>
				<span className={classes.Title}>{details?.title}</span>
				<span className={classes.Number}>{details?.number}</span>
			</div>
		</div>
	);
};

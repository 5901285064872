import { useEffect, useState } from "react";
import classes from "./Dashboard.module.scss";
import { Card, SegmentedControl } from "@streets-heaver/shui2";
import {
	AppointmentCalendar,
	LoadingSpinner,
	NextPatientCard,
	SchedulerSettings,
	UpcomingAppointments,
	PatientCardPanel,
} from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { ScreenSize, useSessionStorage, PreviewPanel, useContentSizeClass } from "@streetsheaver/compucore";
import { Outlet } from "react-router";
import clsx from "clsx";

const mobileSegments = [
	{ label: "Next patient", value: "Next patient" },
	{ label: "Upcoming", value: "Upcoming" },
	{ label: "Calendar", value: "Calendar" },
];

const tabletSegments = [
	{ label: "Upcoming", value: "Upcoming" },
	{ label: "Calendar", value: "Calendar" },
];

export default function Dashboard() {
	const width = useContentSizeClass();

	const { currentClinician, userSecurityFlags } = useCurrentClinicians();

	const [segment, setSegment] = useSessionStorage("dashboard.mobileTab", mobileSegments[0].value);

	const [dayRange, setDayRange] = useState(parseInt(localStorage.getItem("compucare.calendarDayRange")) || 7);
	const [startTime, setStartTime] = useState(parseInt(localStorage.getItem("compucare.calendarStartTime")) || 8);
	const [timescale, setTimescale] = useState(parseInt(localStorage.getItem("compucare.calendarInterval")) || 2);

	const [selectedBooking, setSelectedBooking] = useState(null);

	useEffect(() => {
		if (width >= ScreenSize.TabletPortrait && segment === "Next patient") {
			setSegment("Upcoming");
		}
	}, [width, segment, setSegment]);

	let nextPatient = <NextPatientCard hasPermission={userSecurityFlags?.includes(`dashboard-nextpatientappointment`)} />;

	let upcomingAppointments = (
		<Card className={clsx(classes.fullWidthCard, classes.mobileCard)}>
			<UpcomingAppointments
				setSelectedBooking={setSelectedBooking}
				selectedBookingId={selectedBooking?.id}
				hasPermission={userSecurityFlags?.includes(`dashboard-upcomingappointment`)}
			/>
		</Card>
	);

	let calendar = (
		<div className={classes.calenderWrapper}>
			{width < ScreenSize.TabletPortrait && (
				<div className={classes.schedulerSettings}>
					<SchedulerSettings
						timescale={timescale}
						setTimescale={setTimescale}
						startTime={startTime}
						setStartTime={setStartTime}
						dayRange={dayRange}
						setDayRange={setDayRange}
						hasPermission={userSecurityFlags?.includes(`scheduler`)}
					/>
				</div>
			)}
			<Card className={clsx(classes.fullWidthCard, classes.mobileCard)}>
				<AppointmentCalendar
					timescale={timescale}
					setTimescale={setTimescale}
					startTime={startTime}
					setStartTime={setStartTime}
					dayRange={dayRange}
					setDayRange={setDayRange}
					setSelectedBooking={setSelectedBooking}
					hasPermission={userSecurityFlags?.includes(`scheduler`)}
				/>
			</Card>
		</div>
	);

	return (
		<div
			className={clsx(classes.dashboard, width < ScreenSize.TabletLandscape && classes.mobile)}
			data-testid={"pageDashboard"}
		>
			<PreviewPanel
				onBackButtonClick={
					selectedBooking
						? () => {
								setSelectedBooking(null);
							}
						: undefined
				}
				title={selectedBooking && `${selectedBooking.type} booking`}
			>
				{selectedBooking && (
					<PatientCardPanel
						bookingType={selectedBooking.type}
						bookingUniqueId={selectedBooking.id}
						overrideTab={selectedBooking.overrideTab}
					/>
				)}
			</PreviewPanel>

			{!currentClinician ? (
				<LoadingSpinner />
			) : (
				<>
					<Outlet />
					{width >= ScreenSize.TabletLandscape ? (
						<div className={classes.verticalLayout}>
							<div className={classes.topSection}>
								<div className={classes.cardSection} data-testid={"nextpatient"}>
									<p className={classes.cardTitle}>Next Patient</p>
									{nextPatient}
								</div>
								<div className={`${classes.cardSection} ${classes.desktopUpcomApp}`}>
									<p className={classes.cardTitle}>Appointments</p>
									{upcomingAppointments}
								</div>
							</div>
							<div className={`${classes.cardSection} ${classes.desktopCalendarCardSection}`}>
								<p className={classes.cardTitle}>Calendar</p>
								{calendar}
							</div>
						</div>
					) : (
						<div className={classes.mobileView} data-testid="mobileViewControls640">
							<div style={{ flexShrink: 0 }}>
								<SegmentedControl
									segments={width >= ScreenSize.TabletPortrait ? tabletSegments : mobileSegments}
									onChange={setSegment}
									groupName={"mobileTabs"}
									type="switch"
									updateSelectedSegment={segment}
									defaultSegment={segment}
									automationId={"dashboard-tabs"}
								/>
							</div>
							{segment === "Next patient" ? nextPatient : segment === "Upcoming" ? upcomingAppointments : calendar}
						</div>
					)}
				</>
			)}
		</div>
	);
}

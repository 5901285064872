import React, { useState, useEffect, useRef } from "react";
import { useMeasure } from "@uidotdev/usehooks";
import { Divider, Calendar, MenuButton } from "@streets-heaver/shui2";
import classes from "./UpcomingAppointments.module.scss";
import { faEllipsis } from "@shfortawesome/pro-light-svg-icons";
import { faEllipsis as farEllipsis } from "@shfortawesome/pro-regular-svg-icons";
import { faEllipsis as fasEllipsis } from "@shfortawesome/pro-solid-svg-icons";
import { faEllipsis as fadEllpisis } from "@shfortawesome/pro-duotone-svg-icons";
import { DateToolbar } from "../DateToolbar/DateToolbar";
import { EventItem } from "../EventItem/EventItem";
import { TodayButton } from "../TodayButton/TodayButton";
import { useUpcomingAppointments } from "../../api/hooks/useUpcomingAppointments";
import { ScreenSize, useContentSizeClass, usePreviewPanel, useSessionStorage } from "@streetsheaver/compucore";
import { getAppointmentType } from "../../utils/getAppointmentType";
import { NoAccessMessage } from "../../components";

export const UpcomingAppointments = ({ setSelectedBooking, selectedBookingId, hasPermission }) => {
	const today = new Date();
	const [upcomingAppointmentsRef, { width: containerWidth }] = useMeasure();
	const width = useContentSizeClass();
	const [selectedDate, setSelectedDate] = useSessionStorage("upcomingAppointments.selectedDate", today);
	const [showCalendar, setShowCalendar] = useState(width >= ScreenSize.TabletPortrait);
	const eventItemListRef = useRef(null);
	const mobileEventsBoolean = width < ScreenSize.TabletPortrait || (showCalendar && width < ScreenSize.Large);

	const { setIsSheetMaximised, setIsVisible: setPreviewPanelVisible } = usePreviewPanel();

	const ellipsisMenuItems = [
		{
			items: [
				{
					text: "Show calendar",
					isChecked: showCalendar,
					onClick: () => {
						setShowCalendar(true);
					},
				},
				{
					text: "Hide calendar",
					isChecked: !showCalendar,
					onClick: () => {
						setShowCalendar(false);
					},
				},
			],
		},
	];

	const selectedDateObject = new Date(selectedDate);

	const {
		loadingAppointment,
		dataAppointment,
		errorAppointment,
		hasMoreAppointments,
		isFetchingMoreAppointments,
		fetchMoreAppointments,
	} = useUpcomingAppointments(
		selectedDateObject.toDateString(),
		Math.ceil(eventItemListRef?.current?.offsetHeight / 66) || 7,
		hasPermission,
	);

	useEffect(() => {
		if (containerWidth && containerWidth <= 744) setShowCalendar(false);
	}, [containerWidth]);

	useEffect(() => {
		if (
			hasMoreAppointments &&
			!isFetchingMoreAppointments &&
			!(eventItemListRef?.current?.offsetHeight < eventItemListRef?.current?.scrollHeight)
		) {
			fetchMoreAppointments();
		}
	}, [eventItemListRef?.current?.offsetHeight, fetchMoreAppointments, hasMoreAppointments, isFetchingMoreAppointments]);

	if (!hasPermission) {
		return <NoAccessMessage />;
	}

	return (
		<div
			className={classes.UpcomingAppointments}
			ref={upcomingAppointmentsRef}
			id="child"
			data-testid="upcoming-appointments"
		>
			<div className={classes.Header}>
				{width >= ScreenSize.TabletPortrait && width < ScreenSize.Small && (
					<TodayButton setCurrentDate={setSelectedDate} />
				)}
				<DateToolbar
					todayButton={width >= ScreenSize.Small}
					menuButton={!showCalendar}
					currentDate={selectedDateObject}
					setCurrentDate={(e) => setSelectedDate(e)}
				/>
				<div className={classes.CalendarToggle} style={{ visibility: containerWidth > 744 ? "visible" : "hidden" }}>
					<MenuButton
						noChevron
						type="subtle"
						size="large"
						colour="grey"
						icon={{
							restIcon: faEllipsis,
							hoverIcon: farEllipsis,
							pressedIcon: fasEllipsis,
							selectedIcon: fadEllpisis,
							primaryColour: "var(--appColour)",
							secondaryColour: "var(--appColour)",
						}}
						menuProps={{ items: ellipsisMenuItems, hasCheckmarks: true }}
						portalTarget={document.body}
						automationId="upcoming-appointments-calendar-toggle"
					/>
				</div>
			</div>
			<div className={classes.Body}>
				{showCalendar && (
					<div className={classes.CalendarContainer}>
						<Calendar
							type="transparent"
							date={selectedDateObject}
							onSelected={(e) => setSelectedDate(e)}
							automationId={"upcoming-appointments-calendar"}
						/>
					</div>
				)}
				<div
					className={classes.EventItemList}
					onScroll={(e) => {
						if (
							e.target.scrollHeight - e.target.offsetHeight - e.target.scrollTop < 64 &&
							!loadingAppointment &&
							!isFetchingMoreAppointments &&
							hasMoreAppointments
						) {
							fetchMoreAppointments();
						}
					}}
					ref={eventItemListRef}
					data-testid="upcoming-appointments-list"
				>
					{loadingAppointment ? (
						<p className={classes.NoEvents}>Loading appointments for this day.</p>
					) : errorAppointment ? (
						<p className={classes.NoEvents} data-testid="appointments-errortext">
							Error fetching appointments for this day.
						</p>
					) : (
						dataAppointment?.pages &&
						dataAppointment?.pages.map((page, pagei) => {
							return page?.data?.length > 0 ? (
								page?.data?.map((event, eventi) => {
									return (
										<React.Fragment key={`${page}-${eventi}`}>
											<EventItem
												appointment={event}
												onClick={() => {
													setSelectedBooking({
														id: event.bookingUniqueId,
														type: getAppointmentType(event.type),
													});
													setPreviewPanelVisible(true);
													setIsSheetMaximised(true);
												}}
												isSelected={event.bookingUniqueId === selectedBookingId && !!selectedBookingId}
												height={mobileEventsBoolean ? 62 : 66}
												compact={mobileEventsBoolean}
												automationId="upcoming-appointments-event"
												setSelectedBooking={setSelectedBooking}
											/>
											{!(pagei === dataAppointment?.pages?.length - 1 && eventi === page?.data?.length - 1) && (
												<Divider />
											)}
										</React.Fragment>
									);
								})
							) : (
								<p key="empty" className={classes.NoEvents} data-testid="noappointments-text">
									No appointments for this day.
								</p>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
};

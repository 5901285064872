import clsx from "clsx";
import classes from "./PatientCard.module.scss";
import { PatientHeader } from "../PatientHeader/PatientHeader";
import { AppointmentBody } from "../AppointmentBody/AppointmentBody";
import { AppointmentDetails } from "../AppointmentDetails/AppointmentDetails";
import { EpisodeOfCareCard } from "../EpisodeOfCareCard/EpisodeOfCareCard";
import { MessageBar } from "@streets-heaver/shui2";

export const PatientCardNextPatient = ({ ghost = false, appointment, automationId = "patientcard-nextpatient" }) => {
	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<PatientHeader ghost={ghost} patient={appointment} automationId={`${automationId}-header`} />
			<div className={clsx(classes.ScrollContainer, classes.TopMargin)}>
				<AppointmentBody
					ghost={ghost}
					customHeader={"Next appointment"}
					appointment={appointment}
					automationId={`${automationId}-body`}
				/>
				<AppointmentDetails
					ghost={ghost}
					appointment={{ ...appointment, type: "OP" }}
					automationId={`${automationId}-tablist`}
				/>
			</div>
		</div>
	);
};

export const PatientCardPreviewPanel = ({
	ghost = false,
	type,
	appointment,
	overrideTab,
	automationId = "patientcard-previewpanel",
}) => {
	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<div className={classes.ScrollContainer}>
				<PatientHeader ghost={ghost} patient={appointment} isRounded automationId={`${automationId}-header`} />
				<EpisodeOfCareCard ghost={ghost} data={appointment} automationId={`${automationId}-eoc`} />
				{appointment?.type === "OPSB" && <MessageBar body={"This booking is linked to a surgical booking."} />}
				<AppointmentBody
					ghost={ghost}
					customHeader={`${type} booking`}
					appointment={appointment}
					automationId={`${automationId}-body`}
					hasActions
				/>
				<AppointmentDetails
					ghost={ghost}
					appointment={appointment}
					overrideTab={overrideTab}
					automationId={`${automationId}-tablist`}
				/>
			</div>
		</div>
	);
};

export const PatientCardPatientView = ({ ghost = false, appointment, automationId = "patientcard-patientview" }) => {
	return (
		<div className={classes.PatientCard} data-testid={automationId}>
			<div className={classes.ScrollContainer}>
				<div className={classes.AppointmentInfo}>
					<AppointmentBody ghost={ghost} appointment={appointment} automationId={`${automationId}-body`} />
				</div>
				<AppointmentDetails ghost={ghost} appointment={appointment} automationId={`${automationId}-tablist`} />
			</div>
		</div>
	);
};

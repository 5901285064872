import { Dialog, Field, Lookup, SegmentedControl } from "@streets-heaver/shui2";
import { useNavigate } from "react-router";
import classes from "./PrintModal.module.scss";
import { Datebox, NoAccessMessage } from "../../components";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";

export const PrintModal = ({ type }) => {
	const navigate = useNavigate();
	const { userSecurityFlags } = useCurrentClinicians();

	const tabs = [
		{ label: "Clinic lists", value: "clinic" },
		{ label: "Surgical lists", value: "surgical" },
	];

	if (!userSecurityFlags?.includes(`toolbar-printing`))
		return (
			<Dialog
				visible
				fixedHeight={"100px"}
				header="Print lists"
				onCloseButtonClick={() => navigate("..")}
				buttons={{
					primaryButton: { children: "Print", disabled: true },
					secondaryButton: { children: "Cancel", onClick: () => navigate("..") },
				}}
			>
				<NoAccessMessage />
			</Dialog>
		);

	return (
		<Dialog
			visible
			header="Print lists"
			onCloseButtonClick={() => navigate("..")}
			buttons={{
				primaryButton: { children: "Print" },
				secondaryButton: { children: "Cancel", onClick: () => navigate("..") },
			}}
		>
			<div className={classes.printForm}>
				<SegmentedControl
					type="switch"
					defaultSegment={type}
					onChange={(e) => navigate(`../print/${e}`)}
					segments={tabs}
				/>
				<div className={classes.dateSelection}>
					<Field label="Date from">
						<Datebox type="filledDarker" />
					</Field>
					<Field label="Date to">
						<Datebox type="filledDarker" />
					</Field>
				</div>
				<Field label="Template">
					<Lookup />
				</Field>
			</div>
		</Dialog>
	);
};

export const getAppointmentCountdown = (date1, date2) => {
	let hours = date1.diff(date2, "h");
	let minutes = date1.diff(date2, "m") % 60;

	return {
		hours: hours,
		minutes: minutes,
		text: hours ? `${hours}h ${minutes}m` : `${minutes}m`,
	};
};

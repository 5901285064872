import { useReferrersLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const ReferrersLookup = () => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useReferrersLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={"Referrer"}
			lookupName={"referrer"}
			setSearchTerm={setSearchTerm}
			query={query}
		/>
	);
};

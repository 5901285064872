import { usePurchasersLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const PurchasersLookup = () => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = usePurchasersLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={"Purchaser"}
			lookupName={"purchaser"}
			setSearchTerm={setSearchTerm}
			query={query}
		/>
	);
};

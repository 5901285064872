import { useInfiniteQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../../makeApiRequest";
import { useDataSources } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../useClinicians";

export const useCountriesLookup = (searchText) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const query = useInfiniteQuery({
		initialPageParam: 0,
		queryKey: ["lookup", "code-table", "countries", searchText],
		queryFn: async ({ pageParam }) => {
			const data = await getCountries(
				currentDataSource?.DataSourceId,
				currentClinician,
				pageParam,
				12,
				searchText,
			);
			return data;
		},
		getNextPageParam: (lastPage) => {
			return lastPage?.nextPageIndex;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician,
	});
	return {
		...query,
		data: query?.data?.pages?.flatMap((page) =>
			page?.data?.map((result, index) => {
				return { ...result, bookingId: index };
			}),
		),
	};
};

const getCountries = async (dataSourceId, clinicianId, skip = 0, take, searchText = "") => {
	const data = await makeApiRequest(
		`${dataSourceId}/clinician/${clinicianId}/lookup/countries?&skip=${skip}&take=${take + 1}${
			searchText && searchText !== "" ? `&searchText=${encodeURIComponent(searchText)}` : ""
		}`,
	);
	return { data: data.slice(0, take), nextPageIndex: data?.length >= take + 1 ? skip + take : undefined };
};

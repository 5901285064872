import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useFilterList = (initialFilters, validationFunction = (value) => value) => {
	const [searchParams, setSearchParams] = useSearchParams();
    
	const filtersFromURL = useMemo(() => {
        const filtersClone = { ...initialFilters };

		Object.keys(filtersClone).forEach((filterName) => {
			const filter = filtersClone[filterName];
			let filterFromURL = searchParams.getAll(filterName);
            filterFromURL = filterFromURL.map(value => {
                if (value === "false") return false;
                if (value === "true") return true;
                return value;
            })
			if (filterFromURL.length) filter.value = filterFromURL;
		});

		return validationFunction(filtersClone, false);
	}, []);

	const [appliedFilters, setAppliedFilters] = useState(filtersFromURL);

	const updateAppliedFilters = (filters, showToast) => {
		typeof filters === "function"
			? setAppliedFilters((prev) => validationFunction(filters(prev, showToast)))
			: setAppliedFilters(validationFunction(filters, showToast));
	};

	useEffect(() => {
		let paramsObject = {};
		for (const [filterName, filter] of Object.entries(appliedFilters)) {
			paramsObject[filterName] = filter.value;
		}

		setSearchParams(paramsObject);
	}, [appliedFilters, setSearchParams]);

	return { appliedFilters, updateAppliedFilters };
};

import { Divider, TextArea } from "@streets-heaver/shui2";
import { Controller, useFormContext } from "react-hook-form";
import { FormField } from "../FormField/FormField";
import { NewPatient } from "./Sections/NewPatient/NewPatient";
import { Booking } from "./Sections/Booking/Booking";
import { Purchaser } from "./Sections/Purchaser/Purchaser";
import { FollowUpPatient } from "./Sections/FollowUpPatient/FollowUpPatient";
import classes from "./AppointmentForm.module.scss";

export const AddAppointmentForm = ({ type }) => {
	const methods = useFormContext();

	const notesName = "notes";

	return (
		<div className={classes.appointmentForm}>
			{type === "follow-up" ? <FollowUpPatient /> : <NewPatient />}
			<Divider />
			<Booking />
			<Divider />
			<Purchaser />
			<Divider />
			<FormField label="Notes" name={notesName}>
				<Controller
					control={methods.control}
					name={notesName}
					render={({ field: { onChange, value } }) => (
						<TextArea type="filledDarker" size="large" value={value} onChange={onChange} />
					)}
				/>
			</FormField>
		</div>
	);
};

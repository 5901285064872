import classes from "./BulletList.module.scss";

export const BulletList = ({ list, name }) => {
	return list?.length > 0 ? (
		<ul className={classes.bulletList}>
			{list?.map((item, i) => (
				<li key={`${name}-list-${i}`}>{item}</li>
			))}
		</ul>
	) : (
		<p>No {name}</p>
	);
};

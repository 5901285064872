import { InfoTable } from "./InfoTable";
import { useContentSizeClass, useLocalStorage, usePreviewPanel, ScreenSize } from "@streetsheaver/compucore";
import { useCurrentClinicians } from "../../api/hooks/useClinicians";
import { getDefaultColumns, mobileColumns } from "../../utils";
import { getExpandingRow } from "./cellComponents";

export const MyPatientsTable = ({
	query,
	name,
	columns,
	setFiltersPopupOpen,
	filtersCount,
	setSelectedBooking,
	tableRef,
}) => {
	const width = useContentSizeClass();
	const { userSecurityFlags } = useCurrentClinicians();
	const [shownColumns, setShownColumns] = useLocalStorage(`mypatients.${name}-shownColumns`, getDefaultColumns(name));

	const { setIsSheetMaximised, setIsVisible: setPreviewPanelVisible } = usePreviewPanel();

	return (
		<InfoTable
			columnData={{ data: columns, widths: {} }}
			mobileColumnData={{ data: mobileColumns, widths: {} }}
			useMobileColumns={width < ScreenSize.TabletLandscape}
			data={query?.data || []}
			onRowClick={(row) => {
				setSelectedBooking({
					id: row?.bookingUniqueId,
					type: name.charAt(0).toUpperCase() + name.slice(1),
				});
				setIsSheetMaximised(true);
				setPreviewPanelVisible(true);
			}}
			noDataMessage={`No ${name} bookings`}
			hasMoreRows={query.hasNextPage}
			fetchNextPage={query.fetchNextPage}
			shouldFetchNextPage={query.hasNextPage && !query.isFetchingNextPage}
			ghost={query.isLoading}
			isExpanding={width < ScreenSize.TabletLandscape}
			ExpandedRowComponent={getExpandingRow(name)}
			shownColumns={width >= ScreenSize.TabletLandscape ? shownColumns : undefined}
			setShownColumns={setShownColumns}
			onFiltersClick={width < 640 ? () => setFiltersPopupOpen(true) : undefined}
			filtersCount={filtersCount}
			hasPermission={userSecurityFlags.includes(`mypatients-${name}`)}
			automationId={`mypatients-${name}-table`}
			tableRef={tableRef}
		/>
	);
};

import dayjs from "dayjs";
import { z } from "zod";

export const dateOfBirthSchema = z.nullable(
	z.date().refine((value) => !dayjs(value).isAfter(dayjs(), "day"), { message: "Date of birth must be in the past." }),
);

const genderSchema = z.object(
	{ recordId: z.union([z.number(), z.string()]) },
	{ message: "Gender is required. Please choose a gender for the patient." },
);

export const identitySchema = {
	dateOfBirth: dateOfBirthSchema,
	gender: genderSchema,
};

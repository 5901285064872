import { Dialog } from "@streets-heaver/shui2";
import classes from "./FiltersDialog.module.scss";

export const FiltersDialog = ({ visible, setVisible, setAppliedFilters, defaultFilters, getSelectedFilters, children }) => {

	return (
		<Dialog
			visible={visible}
			automationId="filters-dialog"
			header="Filters"
			noSetWidth
			onOutsideClick={() => setVisible(false)}
			onCloseButtonClick={() => setVisible(false)}
			buttons={{
				primaryButton: {
					children: "Apply filters",
					onClick: () => {
						setAppliedFilters(getSelectedFilters());
						setVisible(false);
					},
				},
				secondaryButton: { children: "Cancel", onClick: () => setVisible(false) },
				tertiaryButton: {
					children: "Clear all filters",
					className: classes.redButton,
					onClick: () => {
						setAppliedFilters(defaultFilters, false);
					},
				},
			}}
		>
			{visible && (
				<div className={classes.filtersPopup}>
					<div className={classes.popupSection}>{children}</div>
				</div>
			)}
		</Dialog>
	);
};


import { useFormError } from "../../../../../utils";
import { SegmentedControl, Textbox } from "@streets-heaver/shui2";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { PreferredNotification } from "./PreferredNotification";
import { AdditionalPhoneNumbers } from "./AdditionalPhoneNumbers";
import { Address } from "./Address";

export const Contact = () => {
	const form = useFormContext();

	const lineOne = form.register("contact.primary.address.addressLine1");
	const lineTwo = form.register("contact.primary.address.addressLine2");
	const lineThree = form.register("contact.primary.address.addressLine3");
	const townCity = form.register("contact.primary.address.addressLine4");
	const county = form.register("contact.primary.address.addressLine5");
	const postCode = form.register("contact.primary.address.postCode");
	const countryFieldName = "contact.primary.address.country";
	const email = form.register("contact.primary.email");
	const mobilePhone = form.register("contact.primary.mobilePhone");
	const workPhone = form.register("contact.primary.workPhone");
	const mainPhone = form.register("contact.primary.mainPhone");

	const notificationMethodConfirmed = form.register("contact.primary.notificationMethodConfirmed");
	const preferredNotificationMethod = form.register("contact.primary.preferredNotificationMethod");
	const preferredWrittenMethod = form.register("contact.primary.preferredWrittenMethod");

	const address = form.register("contact.primary.address", { deps: [lineOne.name, postCode.name] });

	const preferredWrittenMethodField = useWatch({ name: preferredWrittenMethod.name });
	const preferredNotificationMethodField = useWatch({ name: preferredNotificationMethod.name });
	const notificationMethodConfirmedField = useWatch({ name: notificationMethodConfirmed.name });
	const mobilePhoneIsMandatory =
		notificationMethodConfirmedField &&
		(preferredNotificationMethodField?.value === "mobile" ||
			preferredNotificationMethodField?.value === "sms-to-mobile");
	const mainPhoneIsMandatory =
		notificationMethodConfirmedField && preferredNotificationMethodField?.value === "sms-to-landline";

	return (
		<div data-section id="contact">
			<FormGroup title="Contact">
				<Address
					isMandatory={preferredWrittenMethodField === "1"}
					control={form.control}
					address={address}
					addressLine1={lineOne}
					addressLine2={lineTwo}
					addressLine3={lineThree}
					townCity={townCity}
					county={county}
					postCode={postCode}
					countryFieldName={countryFieldName}
				/>
				<FormField
					label="Email"
					name={email.name}
					isMandatory={
						preferredWrittenMethodField === "2" ||
						(notificationMethodConfirmedField && preferredNotificationMethodField?.value === "2")
					}
				>
					<Textbox
						type={"filledDarker"}
						inputName={email.name}
						onChange={email.onChange}
						onBlur={email.onBlur}
						reference={email.ref}
						size="large"
						isError={useFormError(email.name).length > 0}
					/>
				</FormField>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Mobile Phone" name={mobilePhone.name} isMandatory={mobilePhoneIsMandatory}>
						<Textbox
							type={"filledDarker"}
							inputName={mobilePhone.name}
							onChange={mobilePhone.onChange}
							onBlur={mobilePhone.onBlur}
							reference={mobilePhone.ref}
							size="large"
							isError={useFormError(mobilePhone.name).length > 0}
						/>
					</FormField>
				</div>

				<AdditionalPhoneNumbers
					mainPhoneField={useWatch({ name: mainPhone.name })}
					workPhoneField={useWatch({ name: workPhone.name })}
					workPhone={workPhone}
					mainPhone={mainPhone}
					mainPhoneIsMandatory={mainPhoneIsMandatory}
					hasMainPhoneError={useFormError(mainPhone.name).length > 0}
					hasWorkPhoneError={useFormError(workPhone.name).length > 0}
				/>

				<PreferredNotification
					notificationMethodConfirmed={notificationMethodConfirmed}
					preferredNotificationMethod={preferredNotificationMethod}
					mobilePhone={mobilePhone}
					email={email}
					mainPhone={mainPhone}
				/>

				<FormField label="Preferred written method" name={preferredWrittenMethod.name}>
					<div style={{ maxWidth: "240px" }}>
						<Controller
							name={preferredWrittenMethod.name}
							render={({ field: { value, onChange } }) => (
								<SegmentedControl
									onChange={(e) => {
										onChange(e);
										form.trigger([lineOne.name, postCode.name, email.name]);
									}}
									defaultSegment={value}
									type="tabs"
									segments={[
										{ value: "2", label: "Email" },
										{ value: "1", label: "Post" },
									]}
								/>
							)}
						/>
					</div>
				</FormField>
			</FormGroup>
		</div>
	);
};

import clsx from "clsx";
import dayjs from "dayjs";
import classes from "./TimeDisplay.module.scss";
import { Ghost } from "@streets-heaver/shui2";
import { durationFromDates, timeFormat, getCountdownBadge, nightsBetweenDates } from "../../utils";

export const TimeDisplay = ({ type = "OP", start, end, column = false, ghost }) => {
	return ghost ? (
		<Ghost height="20px" width="200px" />
	) : (
		<div
			className={classes.AppointmentTime}
			style={{ flexDirection: column ? "column" : "row", alignItems: column ? "baseline" : "center" }}
		>
			{getCountdownBadge(dayjs(), start, end)}
			{!column && <div className={classes.Divider} />}
			{type === "IP" ? (
				<div className={clsx(classes.Time, column ? classes.Column : classes.Row)}>
					{nightsBetweenDates(start, end)}
				</div>
			) : (
				<div className={clsx(classes.Time, column ? classes.Column : classes.Row)}>
					<p>{`${timeFormat(start)} - ${timeFormat(end)}`}</p>
					{!column && <div className={classes.Divider} />}
					<p className={classes.AppointmentLength}>{durationFromDates(start, end)}</p>
				</div>
			)}
		</div>
	);
};

import { useFormContext, useWatch, Controller } from "react-hook-form";
import { Switch } from "@streets-heaver/shui2";
import { FormField } from "../../../FormField/FormField";
import { Radio } from "../../../../Radio/Radio";

export const PreferredNotification = ({
	notificationMethodConfirmed,
	preferredNotificationMethod,
	mobilePhone,
	email,
	mainPhone,
}) => {
	const showPreferredNotificationMethodField = useWatch({ name: notificationMethodConfirmed.name });
	const form = useFormContext();

	return (
		<>
			<FormField label="Notification method confirmed?">
				<Controller
					name={notificationMethodConfirmed.name}
					render={({ field: { value, onChange } }) => (
						<Switch
							showStateText
							handleToggle={(e) => {
								onChange(e);
								form.trigger([mobilePhone.name, email.name, mainPhone.name]);
							}}
							isOn={value}
						/>
					)}
				/>
			</FormField>

			{showPreferredNotificationMethodField && (
				<FormField name={preferredNotificationMethod.name} label="Preferred notification method" isMandatory>
					<Controller
						name={preferredNotificationMethod.name}
						render={({ field: { value, onChange } }) => (
							<Radio
								items={[
									{ value: "3", label: "Email" },
									{ value: "4", label: "Mobile" },
									{ value: "1", label: "SMS to main phone" },
									{ value: "2", label: "SMS to mobile" },
								]}
								value={value?.value}
								onChange={(e) => {
									onChange(e);
									form.trigger([preferredNotificationMethod.name, mobilePhone.name, email.name, mainPhone.name]);
								}}
							/>
						)}
					/>
				</FormField>
			)}
		</>
	);
};

import {
	bookingSchema,
	faceToFaceContactTypeRefinement,
} from "../../components/FormComponents/AppointmentForms/Sections/Booking/bookingSchema";
import {
	purchaserMembershipNumberRefinement,
	purchaserSchema,
} from "../../components/FormComponents/AppointmentForms/Sections/Purchaser/purchaserSchema";
import { z } from "zod";

export const editAppointment = z
	.object({
		...bookingSchema,
		...purchaserSchema,
		notes: z.string().optional(),
	})
	.refine(faceToFaceContactTypeRefinement.condition, faceToFaceContactTypeRefinement.args)
	.refine(purchaserMembershipNumberRefinement.condition, purchaserMembershipNumberRefinement.args);

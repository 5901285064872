import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";

export const useSchedulerEvents = (dates, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const results = useQuery({
		queryKey: ["schedulerEvents", currentClinician, dates],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/scheduler/events?${dates
					.map((date) => `dates=${encodeURIComponent(dayjs(date).format())}`)
					.join("&")}`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting scheduler events Failed");

			return data;
		},
		staleTime: Infinity,
		enabled: !!currentDataSource && !!currentClinician && !!dates && enabled,
	});

	return results;
};

import { CompoundButton, Dialog } from "@streets-heaver/shui2";
import { useNavigate } from "react-router";
import { useDataSources } from "@streetsheaver/compucore";
import { faPlus, faArrowRotateRight } from "@shfortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./AppointmentTypeModal.module.scss";

export const AppointmentTypeModal = () => {
	const { currentDataSource } = useDataSources();
	const navigate = useNavigate();
	return (
		<Dialog visible header="Please choose" onOutsideClick={() => navigate("..")}>
			<div className={classes.appointmentButtons}>
				<CompoundButton
					illustration={<FontAwesomeIcon icon={faPlus} className={classes.icon} />}
					primaryText="New"
					secondaryText="Create a new appointment with a new episode of care"
					navigation={{ to: `/${currentDataSource.DataSourceId}/add-appointment/new`, replace: true }}
				/>
				<CompoundButton
					illustration={<FontAwesomeIcon icon={faArrowRotateRight} className={classes.icon} />}
					primaryText="Follow up"
					secondaryText="Create a follow up appointment with an existing episode of care"
					navigation={{ to: `/${currentDataSource.DataSourceId}/add-appointment/follow-up`, replace: true }}
				/>
			</div>
		</Dialog>
	);
};

import { z } from "zod";
import { patientLookupSchema } from "../NewPatient/newPatientSchema";

const episodeOfCareLookupSchema = z.object(
	{ episodeOfCareId: z.union([z.number(), z.string()]) },
	{ message: "An episode of care must be selected." },
);

export const followUpPatientSchema = {
	patient: patientLookupSchema,
	episodeOfCare: episodeOfCareLookupSchema,
};

import { Button } from "@streets-heaver/shui2";
import classes from "./FloatingActionButton.module.scss";

export const FloatingActionButton = ({ icon, children, onClick, navigation }) => {
	return (
		<Button className={classes.floatingActionButton} icon={icon} onClick={onClick} navigation={navigation}>
			{children}
		</Button>
	);
};

import dayjs from "dayjs/esm";
import toast from "react-hot-toast";
import { shortDateFormat } from "../../utils";

export function validateFilters(filters) {
	const filtersToApply = {...filters};

	const fromDateToApply = filtersToApply.dateFrom;

	if (fromDateToApply.value.length && new Date() < new Date(fromDateToApply.value[0])) {
		filtersToApply.dateFrom = fromDateToApply.cloneWithNewValue([dayjs().startOf("day").format()]);

		toast.error({
			title: "Invalid date range",
			subtitle: `The 'From' date filter has been set to today (${shortDateFormat(filtersToApply.dateFrom.value[0])}).`,
		});
	}

	return filtersToApply;
}
import classes from "./NextOfKinForm.module.scss";
import { Button } from "@streets-heaver/shui2";
import { faPlus } from "@shfortawesome/pro-regular-svg-icons";
import { useState } from "react";
import { NextOfKinPersonItem } from "./NextOfKinPersonItem";
import { NextOfKinForm } from "./NextOfKinForm";

export const NextOfKinField = ({ data = [], onChange }) => {
	const [newNokDialogOpen, setNewNokDialogOpen] = useState(false);
	const [editingNextOfKin, setEditingNextOfKin] = useState(undefined);

	return (
		<div className={classes.fieldGroup}>
			<p className={classes.title}>Next of kin</p>
			{data.map((nok, i) => (
				<NextOfKinPersonItem
					key={i}
					order={i}
					countOfItems={data.length}
					nok={nok}
					isPrimary={i === 0}
					onEditPressed={() => {
						setEditingNextOfKin({ position: i, nextOfKin: nok });
					}}
					onDeletePressed={() => {
						const removedArr = [...data];
						removedArr.splice(i, 1);
						onChange(removedArr);
					}}
					onOrderChange={(position, delta) => {
						const newArr = [...data];
						newArr.splice(position + delta, 0, newArr.splice(position, 1)[0]);
						onChange(newArr);
					}}
				/>
			))}
			<Button
				icon={{ restIcon: faPlus }}
				onClick={(e) => {
					e.preventDefault();
					setNewNokDialogOpen((prev) => !prev);
				}}
			>
				Add new next of kin
			</Button>
			{newNokDialogOpen && (
				<NextOfKinForm
					visible={newNokDialogOpen}
					onDialogClose={() => setNewNokDialogOpen(false)}
					onSubmit={(nok) => {
						onChange([...data, nok]);
						setNewNokDialogOpen(false);
					}}
				/>
			)}
			{editingNextOfKin && (
				<NextOfKinForm
					visible={editingNextOfKin !== undefined}
					type={"Edit"}
					onDialogClose={() => setEditingNextOfKin(undefined)}
					onSubmit={(nok) => {
						const newArr = [...data];
						newArr.splice(editingNextOfKin.position, 1, nok);
						onChange(newArr);
						setEditingNextOfKin(undefined);
					}}
					defaultValues={editingNextOfKin?.nextOfKin}
				/>
			)}
		</div>
	);
};

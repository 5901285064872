import { Tooltips } from "@streets-heaver/shui2";
import classes from "./TruncatedText.module.scss";
import { useEffect, useId, useRef, useState } from "react";
import { createPortal } from "react-dom";

export const TruncatedText = ({ children }) => {
	const id = useId();
	const textRef = useRef(null);
	const [isTruncated, setIsTruncated] = useState(false);

	useEffect(() => {
		const observer = new ResizeObserver(([textElement]) => {
			setIsTruncated(textElement.target.scrollWidth > textElement.target.offsetWidth);
		});

		if (textRef.current) observer.observe(textRef.current);

		return () => {
			observer.disconnect();
		};
	}, [textRef]);

	return (
		<p className={classes.truncatedText} ref={textRef} data-tooltipid={`truncatedArray-${id}`} tabIndex={isTruncated ? 1 : 0}>
			{children}
			{isTruncated &&
				createPortal(
					<Tooltips   
						tooltips={[
							{
								anchorSelect: `[data-tooltipid='truncatedArray-${id}']`,
								content: <p className={classes.tooltipText}>{children}</p>,
								place: "bottom",
							},
						]}
					/>,
					document.body,
				)}
		</p>
	);
};

import dayjs from "dayjs";

export const getBreachPercentage = (start, breach) => {
	if (!start || !breach) return null;

	const startDate = dayjs(start);
	const breachDate = dayjs(breach);
	const now = dayjs();
	const scale = breachDate.diff(startDate, "days");
	const nowFromStart = now.diff(startDate, "days");

	return (nowFromStart / scale) * (100 - 5);
};

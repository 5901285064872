import { useEffect, useState } from "react";
import classes from "./AppointmentDetails.module.scss";
import { Ghost, TabList } from "@streets-heaver/shui2";
import { dateTimeFormat, durationFromDates, localFromUTC, shortDateFormat } from "../../utils";

const tabs = [
	{ label: "Details", value: "Details" },
	{ label: "Insurance", value: "Insurance" },
	{ label: "Notes", value: "Notes" },
];

export const AppointmentDetails = ({ ghost = false, appointment, overrideTab, automationId }) => {
	const [tab, setTab] = useState(tabs[0].value);
	const localStart = localFromUTC(appointment?.start);
	const localEnd = localFromUTC(appointment?.end);
	const localExpected = localFromUTC(appointment?.expectedDischarge);
	const hasExpected = !!appointment?.expectedDischarge;

	const dischargeFormat = localEnd.diff(localStart, "day") === 0 ? dateTimeFormat : shortDateFormat;

	useEffect(() => {
		if (tabs.find((tab) => tab.value === overrideTab)) setTab(overrideTab);
	}, [overrideTab]);

	const detailsTable =
		appointment?.type === "OP" || ghost || !appointment?.type
			? [
					{
						label: "Duration",
						value: durationFromDates(localStart, localEnd) ?? "-",
					},
					{ label: "Location", value: appointment?.location ?? "-" },
					{ label: "New/Follow up", value: !appointment ? "-" : appointment?.isFollowUp ? "Follow up" : "New" },
				]
			: appointment?.type === "IP"
				? [
						{
							label: "Expected discharge",
							value: hasExpected ? dischargeFormat(localExpected) : dischargeFormat(localEnd),
						},
						{ label: "Actual discharge", value: hasExpected ? dischargeFormat(localEnd) : "-" },
						{ label: "Bed name", value: appointment?.bedName ?? "-" },
						{ label: "Location", value: appointment?.location ?? "-" },
						{ label: "Procedure notes", value: appointment?.procedureNotes ?? "-" },
					]
				: [
						{ label: "Theatre name", value: appointment?.theatreName ?? "-" },
						{ label: "Primary resource", value: appointment?.primaryResource ?? "-" },
						{ label: "Anasthetic type", value: appointment?.anastheticType ?? "-" },
						{ label: "Procedure notes", value: appointment?.procedureNotes ?? "-" },
					];

	if (appointment?.anaesthetists) {
		detailsTable.splice(
			1,
			0,
			...(appointment.anaesthetists?.length
				? appointment.anaesthetists.map((ana, index) => ({
						label: `Anaesthetist ${appointment.anaesthetists?.length > 1 ? index + 1 : ""}`,
						value: ana,
					}))
				: [{ label: `Anaesthetist`, value: "-" }]),
		);
	}

	const insuranceTable = [
		{ label: "Purchaser", value: appointment?.purchaser ?? "-" },
		{ label: "Contract", value: appointment?.contract ?? "-" },
		{ label: "Authorisation", value: appointment?.authorisation ?? "-" },
		{
			label: "Expires",
			value: appointment?.expires ? shortDateFormat(appointment?.expires) : "-",
		},
		{ label: "Membership", value: appointment?.membership ?? "-" },
	];

	const ghostDetail = <Ghost height="20px" width="60px" />;

	return (
		<div className={classes.appointmentDetails} data-testid={automationId}>
			<TabList
				groupName={`${automationId}-groupname`}
				tabs={tabs}
				onChange={setTab}
				updateSelectedTab={tab}
				fitContainer
				automationId={`${automationId}-tabs`}
			/>
			<div className={classes.tabbedDetailsContainer}>
				{(tab === "Details" || tab === "Insurance") && (
					<table className={classes.detailTable}>
						<tbody>
							{(tab === "Details" ? detailsTable : insuranceTable).map((detail, i) => (
								<tr key={`${detail.label}-${i}`}>
									<td className={classes.tableTitle}>{detail.label}</td>
									<td className={classes.tableValue}>{ghost ? ghostDetail : detail.value}</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
				{tab === "Notes" &&
					(ghost ? (
						ghostDetail
					) : !appointment?.notes ? (
						<p className={classes.note}>No notes</p>
					) : (
						<div className={classes.noteContainer}>{appointment?.notes}</div>
					))}
			</div>
		</div>
	);
};

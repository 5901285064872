import {
	faUserDoctor as falUserDoctor,
	faCalendarDays as falCalendarDays,
	faUsers as falUsers,
	faClipboardList as falClipboardList,
	faUsersRectangle as falUsersRectangle,
	faUserGroup as falUserGroup,
} from "@shfortawesome/pro-light-svg-icons";
import {
	faUserDoctor as farUserDoctor,
	faCalendarDays as farCalendarDays,
	faUsers as farUsers,
	faClipboardList as farClipboardList,
	faUsersRectangle as farUsersRectangle,
	faUserGroup as farUserGroup,
} from "@shfortawesome/pro-regular-svg-icons";
import {
	faUserDoctor as fasUserDoctor,
	faCalendarDays as fasCalendarDays,
	faUsers as fasUsers,
	faClipboardList as fasClipboardList,
	faUsersRectangle as fasUsersRectangle,
	faUserGroup as fasUserGroup,
} from "@shfortawesome/pro-solid-svg-icons";
import {
	faUserDoctor as fadUserDoctor,
	faCalendarDays as fadCalendarDays,
	faUsers as fadUsers,
	faClipboardList as fadClipboardList,
	faUsersRectangle as fadUsersRectangle,
	faUserGroup as fadUserGroup,
} from "@shfortawesome/pro-duotone-svg-icons";

export const pages = (pagename, dataSourceId, userSecurityFlags) => {
	const clinicianPages = [];

	if (userSecurityFlags?.includes(`navbar-patients`))
		clinicianPages.push({
			title: "Patients",
			icon: {
				restIcon: falUsers,
				hoverIcon: farUsers,
				pressedIcon: fasUsers,
				selectedIcon: fadUsers,
			},
			to: `https://compucare-dev.streets-heaver.com/patients/${dataSourceId}`,
			selected: false,
		});

	if (userSecurityFlags?.includes(`navbar-people`))
		clinicianPages.push({
			title: "People",
			icon: {
				restIcon: falUserGroup,
				hoverIcon: farUserGroup,
				pressedIcon: fasUserGroup,
				selectedIcon: fadUserGroup,
			},
			to: "",
			selected: false,
		});

	clinicianPages.push({
		title: "Dashboard",
		icon: {
			restIcon: falUserDoctor,
			hoverIcon: farUserDoctor,
			pressedIcon: fasUserDoctor,
			selectedIcon: fadUserDoctor,
		},
		to: `/${dataSourceId}`,
		selected: pagename === "" || pagename === undefined,
	});

	if (userSecurityFlags?.includes(`scheduler`))
		clinicianPages.push({
			title: "Scheduler",
			icon: {
				restIcon: falCalendarDays,
				hoverIcon: farCalendarDays,
				pressedIcon: fasCalendarDays,
				selectedIcon: fadCalendarDays,
			},
			to: "scheduler",
			selected: pagename === "scheduler",
		});

	if (userSecurityFlags?.includes(`mypatients`))
		clinicianPages.push({
			title: "My patients",
			icon: {
				restIcon: falUsersRectangle,
				hoverIcon: farUsersRectangle,
				pressedIcon: fasUsersRectangle,
				selectedIcon: fadUsersRectangle,
			},
			to: "mypatients",
			selected: pagename === "mypatients",
		});

	if (userSecurityFlags?.includes(`orders`))
		clinicianPages.push({
			title: "Orders",
			icon: {
				restIcon: falClipboardList,
				hoverIcon: farClipboardList,
				pressedIcon: fasClipboardList,
				selectedIcon: fadClipboardList,
			},
			to: "orders",
			selected: pagename === "orders",
		});

	return clinicianPages;
};

import { formatNameAsReversed } from "./nameFormatting";
import { localFromUTC, timeFormat } from "./dateFormatting";
import { getAppointmentType } from "./getAppointmentType";

export const addToCalendarIndividualBooking = (booking) => {
	const dateTimeFormat = "YYYYMMDDTHHmmss";
	let startDate = localFromUTC(booking?.start);
	let endDate = localFromUTC(booking?.end);

	const bookingType = getAppointmentType(booking?.type);

	let icsMSG =
		"BEGIN:VCALENDAR\nVERSION:2.0\nBEGIN:VEVENT\nDTSTART:" +
		startDate.format(dateTimeFormat) +
		"\nDTEND:" +
		endDate.format(dateTimeFormat) +
		"\nSUMMARY: " +
		(bookingType + " Hospital Appointment") +
		"\nLOCATION: " +
		(booking?.location ?? "Unknown") +
		"\nDESCRIPTION: " +
		(booking?.patient && !booking?.isPrivate && !booking?.isVIP
			? "You have an appointment for: " + formatNameAsReversed(booking?.forename, booking?.surname, booking?.title)
			: "You have an appointment") +
		" from " +
		timeFormat(startDate) +
		(booking?.location ? " at " + booking?.location : "") +
		"\nEND:VEVENT" +
		"\nEND:VCALENDAR";

	let data = new File([icsMSG], { type: "text/calendar;charset=UTF-8" });

	let ICS = window.URL.createObjectURL(data);
	let link = document.createElement("a");
	link.href = ICS;
	link.download = `${bookingType}-${startDate}-event.ics`;
	link.click();
};

import classes from "./EventItemCompact.module.scss";
import { faSyringe } from "@shfortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatNameAsReversed, getBookingColour, localFromUTC, timeFormat } from "../../../utils";
import { useDataSources } from "@streetsheaver/compucore";
import { NavLink } from "@streets-heaver/shui2";
import { TruncatedArray } from "../../TruncatedArray/TruncatedArray";
import { InfoRow } from "../../InfoRow/InfoRow";
import clsx from "clsx";

export const EventItemCompact = ({
	ghost,
	appointment,
	height,
	onClick = () => {},
	isSelected,
	automationId = "event-item",
}) => {
	const { currentDataSource } = useDataSources();
	const currentDateTime = new Date();
	const past = currentDateTime > appointment?.end;
	const isSuspension = appointment?.type === "SUS";
	const isScheduler = appointment?.renderStart || appointment?.renderEnd;

	return (
		<div
			onClick={onClick}
			className={clsx(
				classes.EventItemCompact,
				isSelected && classes.SelectedEventItem,
				!isScheduler && classes.EventItemAppointments,
			)}
			style={{ height: height }}
			data-testid={automationId}
		>
			<div className={classes.appointment}>
				<div className={classes.status} style={{ backgroundColor: getBookingColour(appointment?.type, past) }} />
				<div className={classes.info} style={{ padding: height > 40 ? "2px 4px 2px 0px" : "unset" }}>
					<div className={classes.patient}>
						<InfoRow info={`Suspension: ${appointment?.suspensionReason}`} hide={!isSuspension} />
						<InfoRow
							info={
								<div className={classes.PatientRow}>
									<p>
										{appointment?.fullNameReverse ??
											formatNameAsReversed(appointment?.forename, appointment?.surname, appointment?.title)}
									</p>
									<NavLink
										type={"default"}
										to={`/${currentDataSource?.DataSourceId}/patient/${appointment?.patientId}`}
										canCopy
									>
										{appointment?.patientId}
									</NavLink>
								</div>
							}
							ghost={ghost}
							hide={height < 23 || isSuspension}
						/>
					</div>
					<InfoRow
						iconSize="medium"
						colour="three"
						icon={<FontAwesomeIcon icon={faSyringe} />}
						info={<TruncatedArray list={appointment?.plannedProcedures || appointment?.procedures} name="procedures" />}
						ghost={ghost}
						hide={(!appointment?.plannedProcedures && !appointment?.procedures) || height < 68 || isSuspension}
					/>
					<InfoRow
						colour="three"
						info={`${timeFormat(localFromUTC(appointment?.start))} - ${timeFormat(localFromUTC(appointment?.end))}`}
						hide={isScheduler}
					/>
				</div>
			</div>
		</div>
	);
};

import axios from "axios";

const defaultInstance = axios.create();

export const makeRequest = (url, { method = "get", params, headers, body, axiosInstance = defaultInstance } = {}) => {
	return makeAxiosRequest(url, { method, params, headers, body, axiosInstance }).then((response) => response?.data);
};

const makeAxiosRequest = (url, { method, params, headers, body, axiosInstance }) => {
	const config = {
		params,
		headers: {
			"Content-Type": "application/json",
			...headers,
		},
	};

	switch (method) {
		case "get":
			return axiosInstance.get(url, config);
		case "post":
			return axiosInstance.post(url, body, config);
		case "put":
			return axiosInstance.put(url, body, config);
		case "delete":
			return axiosInstance.delete(url, { ...config, data: body });
		case "search":
			return axiosInstance.request({ ...config, url, method: "SEARCH", data: body });
		default:
			break;
	}

	throw new Error(`Unrecognized http method ${method}`);
};

import { useDiagnosesLookup } from "../../../api/hooks";
import { SearchableLookup } from "../SearchableLookup/SearchableLookup";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

export const DiagnosesLookup = () => {
	const form = useFormContext();
	const [searchTerm, setSearchTerm] = useState("");
	const query = useDiagnosesLookup(searchTerm);

	return (
		<SearchableLookup
			control={form.control}
			label={"Diagnosis"}
			lookupName={"diagnosis"}
			setSearchTerm={setSearchTerm}
			query={query}
		/>
	);
};

import { Outlet } from "react-router";
import { ClinicianProvider } from "../../providers/ClinicianProvider";
import { MotionConfig } from "framer-motion";
import Frame from "./Frame";
import classes from "./Layout.module.scss";
import { isTesting } from "../../globals/constants";

function Layout() {
	return (
		<MotionConfig reducedMotion={isTesting ? "always" : "user"}>
			<div style={isTesting ? { transitionDelay: "0s !important", transitionDuration: "0s !important" } : {}}>
				<ClinicianProvider>
					<div className={classes.outerContainer}>
						<Frame>
							<div id="contentContainer" className={classes.innerContainer}>
								<Outlet />
							</div>
						</Frame>
					</div>
				</ClinicianProvider>
			</div>
		</MotionConfig>
	);
}

export default Layout;

import { Button } from "@streets-heaver/shui2";
import classes from "./TodayButton.module.scss";
import { faCalendarDay } from "@shfortawesome/pro-light-svg-icons";
import { faCalendarDay as farCalendarDay } from "@shfortawesome/pro-regular-svg-icons";
import { faCalendarDay as fasCalendarDay } from "@shfortawesome/pro-solid-svg-icons";

export const TodayButton = ({ setCurrentDate }) => {
	return (
		<div className={classes.TodayButton}>
			<Button
				type="transparent"
				onClick={() => setCurrentDate(new Date())}
				icon={{ restIcon: faCalendarDay, hoverIcon: farCalendarDay, pressedIcon: fasCalendarDay }}
			>
				Today
			</Button>
		</div>
	);
};

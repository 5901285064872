import { Textbox } from "@streets-heaver/shui2";
import { useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useFormError } from "../../../../../utils";
import { PurchasersLookup } from "../../../lookups/PurchasersLookup";

export const Purchaser = () => {
	const form = useFormContext();

	const authCode = form.register("authCode");
	const membershipNum = form.register("membershipNumber");

	const authCodeError = useFormError(authCode.name).length > 0;
	const membershipNumError = useFormError(membershipNum.name).length > 0;

	return (
		<FormGroup title="Purchaser">
			<PurchasersLookup />
			{form.watch("purchaser") && (
				<>
					<FormField
						label="Authorisation code"
						name={authCode.name}
						isMandatory={form.watch("purchaser").ediAuthorisationType === "Mandatory"}
					>
						<div style={{ maxWidth: "320px" }}>
							<Textbox
								type={"filledDarker"}
								inputName={authCode.name}
								onChange={authCode.onChange}
								onBlur={authCode.onBlur}
								reference={authCode.ref}
								size="large"
								isError={authCodeError}
							/>
						</div>
					</FormField>
					<FormField label="Insurance membership number" isMandatory={form.watch("purchaser").ediAuthorisationType === "EDI"} name={membershipNum.name}>
						<div style={{ maxWidth: "320px" }}>
							<Textbox
								type={"filledDarker"}
								inputName={membershipNum.name}
								onChange={membershipNum.onChange}
								onBlur={membershipNum.onBlur}
								reference={membershipNum.ref}
								size="large"
								isError={membershipNumError}
							/>
						</div>
					</FormField>
				</>
			)}
		</FormGroup>
	);
};

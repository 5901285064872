import { useState } from "react";
import { useNavigate } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AddPatientForm, DiscardChangesDialog, FormWrapper } from "../../components";
import { addPatientFormDefaultValues, addPatientSchema } from "./AddPatientSchema";
import toast from "react-hot-toast";

export const AddPatient = () => {
	const navigate = useNavigate();

	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: addPatientFormDefaultValues(),
		resolver: zodResolver(addPatientSchema),
	});

	const [isConfirmDiscardDialogVisible, setConfirmDiscardDialogVisible] = useState(false);

	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Adding new patient",
				error: "Error adding new patient",
				success: "Added new patient",
			},
		);
		console.log("add patient data", data);
		navigate(-1);
	};

	const onClose = () => navigate(-1);

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper header={"Add new patient"}>
					<FormWrapper.Body>
						<AddPatientForm />
					</FormWrapper.Body>
					<FormWrapper.ButtonBar
						buttons={{
							primaryButton: {
								onClick: methods.handleSubmit(onSubmit),
								children: "Add patient",
							},
							secondaryButton: {
								onClick: () => setConfirmDiscardDialogVisible(true),
								children: "Cancel",
							},
						}}
					/>
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setConfirmDiscardDialogVisible}
			/>
		</>
	);
};

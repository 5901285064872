import { z } from "zod";

const registeredGPSchema = z.nullable(z.object({ id: z.union([z.number(), z.string()]) }));

const gpPracticeSchema = z.nullable(z.object({ practiceId: z.union([z.number(), z.string()]) }));

const registeredOpticianSchema = z.nullable(z.object({ id: z.union([z.number(), z.string()]) }));

const opticianPracticeSchema = z.nullable(z.object({ practiceId: z.union([z.number(), z.string()]) }));

export const sharedSchema = {
	registeredGP: registeredGPSchema,
	gpPractice: gpPracticeSchema,
	registeredOptician: registeredOpticianSchema,
	opticianPractice: opticianPracticeSchema,
};

import classes from './Link.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@shfortawesome/pro-light-svg-icons';
import { NavLink } from 'react-router-dom';

export const Link = ({ text, to, onClick, icon = false }) => {
	return (
		<div className={classes.Link} onClick={onClick}>
			<NavLink to={to}>
				<div className={classes.LinkContent}>
					<p>{text}</p>
					{icon && <FontAwesomeIcon icon={faUpRightFromSquare} />}
				</div>
			</NavLink>
		</div>
	);
};
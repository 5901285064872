import { Textbox } from "@streets-heaver/shui2";
import { useFormContext } from "react-hook-form";
import { useFormError } from "../../../../../utils";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";

export const Identifiers = () => {
	const form = useFormContext();
	const caseNumber = form.register("identifiers.caseNumber");
	const nhsNumber = form.register("identifiers.nhsNumber");

	return (
		<div data-section id="identifiers">
			<FormGroup title="Identifiers">
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Case number" name={caseNumber.name}>
						<Textbox
							type={"filledDarker"}
							inputName={caseNumber.name}
							onChange={caseNumber.onChange}
							onBlur={caseNumber.onBlur}
							reference={caseNumber.ref}
							size="large"
							isError={useFormError(caseNumber.name).length > 0}
						/>
					</FormField>
				</div>
				<div style={{ maxWidth: "300px" }}>
					<FormField label="NHS Number" name={nhsNumber.name}>
						<Textbox
							type={"filledDarker"}
							inputName={nhsNumber.name}
							onChange={nhsNumber.onChange}
							onBlur={nhsNumber.onBlur}
							reference={nhsNumber.ref}
							size="large"
							isError={useFormError(nhsNumber.name).length > 0}
						/>
					</FormField>
				</div>
			</FormGroup>
		</div>
	);
};

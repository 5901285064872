import { useQuery } from "@tanstack/react-query";

export const useGenderOptions = () => {
	return useQuery({
		queryKey: ["code-table", "gender-options"],
		queryFn: getGenderOptions,
	});
};

const getGenderOptions = async () => {
	await new Promise((resolve) => setTimeout(resolve, 1500));
	return genders;
};

const genders = [
	{
		recordId: "12",
		label: "Female",
	},
	{
		recordId: "24",
		label: "Indeterminate",
	},
	{
		recordId: "37",
		label: "Male",
	},
];

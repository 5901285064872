import { Datebox, Switch } from "@streets-heaver/shui2";
import { useEffect, useState } from "react";
import dayjs from "dayjs/esm";
import { FiltersDialog } from "../FiltersDialog";
import classes from "./OrdersFilterDialog.module.scss";

export const OrdersFilterDialog = ({ appliedFilters, setAppliedFilters, defaultFilters, visible, setVisible }) => {
	const [hideInProgressInput, setHideInProgressInput] = useState(false);
	const [hideSeenInput, setHideSeenInput] = useState(false);
	const [dateFromInput, setDateFromInput] = useState(new Date().toISOString());

	const appliedDateFrom = appliedFilters?.dateFrom?.value[0];

	useEffect(() => {
		setHideInProgressInput(appliedFilters?.hideInProgress?.value[0] || false);
		setHideSeenInput(appliedFilters?.hideSeen?.value[0] || false);
		setDateFromInput(appliedDateFrom);
	}, [visible, appliedFilters, appliedDateFrom]);

	function getSelectedFilters() {
		const selectedFilters = {};

		selectedFilters.hideInProgress = appliedFilters.hideInProgress.cloneWithNewValue([hideInProgressInput]);
		selectedFilters.hideSeen = appliedFilters.hideSeen.cloneWithNewValue([hideSeenInput]);
		selectedFilters.dateFrom = appliedFilters.dateFrom.cloneWithNewValue(
			dateFromInput ? [dayjs(dateFromInput).startOf("day").format()] : [],
		);

		return selectedFilters;
	}

	return (
		<FiltersDialog
			defaultFilters={defaultFilters}
			getSelectedFilters={getSelectedFilters}
			setAppliedFilters={setAppliedFilters}
			setVisible={setVisible}
			visible={visible}
		>
			<div className={classes.field} style={{ zIndex: 5 }}>
				<p className={classes.fieldHeader}>Hide in progress</p>
				<div className={classes.switch}>
					<Switch
						key={`inProgressSwitch-${appliedFilters?.hideInProgress?.value[0]}`}
						showStateText
						isOn={appliedFilters?.hideInProgress?.value[0]}
						handleToggle={setHideInProgressInput}
						automationId="in-progress-filter-input"
					/>
				</div>
			</div>
			<div className={classes.field} style={{ zIndex: 5 }}>
				<p className={classes.fieldHeader}>Hide seen</p>
				<div className={classes.switch}>
					<Switch
						key={`seenSwitch-${appliedFilters?.hideSeen?.value[0]}`}
						showStateText
						isOn={appliedFilters?.hideSeen?.value[0]}
						handleToggle={setHideSeenInput}
						automationId="seen-filter-input"
					/>
				</div>
			</div>
			<div className={classes.field}>
				<p className={classes.fieldHeader}>Date from (to today)</p>
				<Datebox
					type="filledDarker"
					key={`fromDatebox-${appliedDateFrom}`}
					customWidth={150}
					onSelected={(date) => date && setDateFromInput(date.toISOString())}
					size="large"
					showInitialDate={!!appliedDateFrom}
					defaultDate={appliedDateFrom && new Date(appliedDateFrom)}
					automationId="from-filter-input"
				/>
			</div>
		</FiltersDialog>
	);
};

import classes from "./ErrorPage.module.scss";
import { ScreenSize, useLocalStorage, useScreenSizeClass, GuestHeader as Header } from "@streetsheaver/compucore";
import { ErrorDisplay, Card } from "@streets-heaver/shui2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor } from "@shfortawesome/pro-duotone-svg-icons";

function determineAutomaticTheme() {
	if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
		return "dark";
	} else {
		return "light";
	}
}

function getStatusText(status) {
	switch (status) {
		case 400:
			return "Bad Request";
		case 401:
			return "Unauthorised";
		case 402:
			return "Payment Required";
		case 403:
			return "Forbidden";
		case 404:
			return "Not Found";
		case 408:
			return "Request Timeout";
		case 500:
			return "Internal Server Error";
		case 501:
			return "Not Implemented";
		case 502:
			return "Bad Gateway";
		case 503:
			return "Service Unavailable";
	}
}

const ErrorPage = ({ error }) => {
	const [storedTheme] = useLocalStorage("theme", "System");
	let theme = storedTheme && storedTheme !== "System" ? storedTheme.toLowerCase() : determineAutomaticTheme();
	document.documentElement.dataset["theme"] = theme;

	const compucareURL =
		window.location.host === "clinician.streets-heaver.com"
			? "https://compucare.streets-heaver.com/home"
			: "https://compucare-dev.streets-heaver.com/home";

	const width = useScreenSizeClass();

	let errorCard = (
		<ErrorDisplay
			primaryButtonProps={{
				children: width < ScreenSize.TabletPortrait ? "Return Home" : "Take me to the home page",
				navigation: { to: compucareURL },
				mobile: width < ScreenSize.TabletPortrait,
			}}
			title={`${error?.status || "Error"}: ${
				error?.status ? getStatusText(error?.status) : "Unexpected Application Error"
			}`}
			hasServiceButton
		>
			{error?.status === 404
				? "It appears that the page you are looking for cannot be found at the moment. This could be due to a mistyped URL, a broken link, or the page might have been moved or deleted."
				: error?.statusText ?? error?.message}
		</ErrorDisplay>
	);

	return (
		<div className={classes.ErrorPage} data-testid={"pageError"}>
			<Header appTitle="Clinician" appIcon={<FontAwesomeIcon icon={faUserDoctor} className={classes.HeaderIcon} />} />
			<div className={classes.Content}>
				{width >= ScreenSize.TabletPortrait ? (
					<Card className={classes.ErrorCard}>{errorCard}</Card>
				) : (
					<div className={classes.ErrorCard}>{errorCard}</div>
				)}
			</div>
		</div>
	);
};

export default ErrorPage;

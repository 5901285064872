import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { isTesting } from "../../globals/constants";

export const useNextPatient = (enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();

	const query = useQuery({
		queryKey: ["nextPatient", currentClinician],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/next${isTesting ? "?isTesting=true" : ""}`,
				{},
				"v2",
			);
			if (data?.request?.response) throw new Error("Getting patient details Failed");
			return data;
		},
		retry: false,
		enabled: !!currentDataSource && !!currentClinician && enabled,
	});

	return query;
};

import { Tooltips } from "@streets-heaver/shui2";
import classes from "./TruncatedArray.module.scss";
import { useId } from "react";
import { createPortal } from "react-dom";
import { BulletList } from "../BulletList/BulletList";

export const TruncatedArray = ({ list, name, numberOnly = false }) => {
	const id = useId();
	return (
		<>
			<div className={classes.TruncatedArray} data-tooltipid={`truncatedArray-${id}`}>
				<p>{list?.length ? list[0] : `No ${name}`}</p>
				{list?.length > 1 && (
					<p>
						(+{list.length - 1}
						{numberOnly ? "" : " more"})
					</p>
				)}
			</div>
			{list?.length !== undefined &&
				list.length > 0 &&
				createPortal(
					<Tooltips
						shui2
						tooltips={[
							{
								anchorSelect: `[data-tooltipid='truncatedArray-${id}']`,
								content: <BulletList list={list} name={name} />,
								place: "bottom",
							},
						]}
					/>,
					document.body,
				)}
		</>
	);
};

import { Dialog } from "@streets-heaver/shui2";

export const DiscardChangesDialog = ({ onClose, visible, setVisible }) => (
	<Dialog
		visible={visible}
		header="Discard changes"
		buttons={{
			primaryButton: {
				children: "Yes, discard progress",
				onClick: () => {
					setVisible(false);
					onClose();
				},
			},
			secondaryButton: {
				children: "No, return to form",
				onClick: () => setVisible(false),
			},
		}}
	>
		Are you sure wish to leave and discard?
	</Dialog>
);

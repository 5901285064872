import { useEffect, useRef, useState } from "react";
import { Badge } from "@streets-heaver/shui2";
import { ScreenSize, useContentSizeClass } from "@streetsheaver/compucore";
import classes from "./TruncatedItemList.module.scss";

export const TruncatedItemList = ({ items, truncatedBadgeProps }) => {
	const width = useContentSizeClass();
	const [unseen, setUnseen] = useState([]);
	const containerRef = useRef(null);

	useEffect(() => {
		let observer = new IntersectionObserver(
			(items) => {
				items.forEach((item) => {
					item.target.style.visibility = !item.isIntersecting ? "hidden" : "visible";
					item.target.setAttribute("data-ishidden", !item.isIntersecting ? "true" : "false");
				});
				let childrenArray = containerRef?.current?.children ? Array.from(containerRef.current.children) : [];
				let currentUnseen = childrenArray?.filter((e) => e.dataset.ishidden === "true");
				setUnseen(currentUnseen);
			},
			{ threshold: 1.0, root: containerRef.current },
		);

		if (containerRef.current !== null && containerRef?.current?.children) {
			Array.from(containerRef.current.children).forEach((child) => {
				observer.observe(child);
			});
		}

		return () => observer.disconnect();
	}, []);

	if (items?.length < 1) return <></>;

	const allOrNone = unseen.length === items?.length;

	return (
		<div
			className={classes.component}
			style={{
				display: allOrNone || width >= ScreenSize.TabletLandscape ? "flex" : "contents",
				flexDirection: allOrNone ? "row-reverse" : "row",
			}}
		>
			<div className={classes.items} ref={containerRef}>
				{items?.map((item) => item)}
			</div>
			{unseen.length > 0 && (
				<Badge
					text={
						allOrNone
							? `${items?.length} ${
									items?.length < 2 ? truncatedBadgeProps?.itemNameSingular : truncatedBadgeProps?.itemNameMultiple
								}`
							: `+${unseen.length} more`
					}
					type={truncatedBadgeProps?.type}
					color={truncatedBadgeProps?.color}
					size={truncatedBadgeProps?.size}
					rounded={truncatedBadgeProps?.rounded}
				/>
			)}
		</div>
	);
};

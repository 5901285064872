import { useRef, useState, useEffect } from "react";
import classes from "./Datebox.module.scss";
import { useOutsideAlerter } from "../../utils/hooks/useOutsideAlerter";
import dayjs from "dayjs/esm";
import utc from "dayjs/esm/plugin/utc";
import { Calendar } from "@streets-heaver/shui2";
import clsx from "clsx";

export const Datebox = ({
	inputName,
	onSelected,
	viewOnly,
	unavailableDates,
	onMonthViewChange,
	showCalendarDropdown = true,
	automationId,
	calendarAutomationId,
	alwaysShowCalendar,
	date,
	customWidth = null,
	type = "outline",
	size = "medium",
	onBlur,
	innerRef,
}) => {
	const [calendarOpen, setCalendarOpen] = useState(false);
	dayjs.extend(utc);
	const dateRef = useRef();

	const newInputRef = useRef(null);
	const inputRef = innerRef ?? newInputRef;

	const updateDate = (dateToSet) => {
		if (dateToSet) {
			let newDate = dayjs(dateToSet).toDate();
			if (newDate.getFullYear() < 1900) {
				newDate.setFullYear(1900);
			}
			if (newDate.getFullYear() > 2191) {
				newDate.setFullYear(2191);
			}
			onSelected(newDate);
		} else {
			onSelected("");
		}
	};

	useOutsideAlerter(dateRef, () => setCalendarOpen(false));
	const listRef = useRef(null);

	const keyDownCalendar = (e) => {
		if (e.type === "keydown") {
			switch (e.key) {
				case "Enter":
					e.preventDefault();
					setCalendarOpen(true);
					return;
				case " ":
					e.preventDefault();
					setCalendarOpen((prev) => !prev);
					return;
				case "Esc":
				case "Escape":
					e.preventDefault();
					setCalendarOpen(false);
					return;
				case "Delete":
					e.preventDefault();
					updateDate(null);
					return;
				default:
					return;
			}
		}
	};

	const handleChange = (e) => {
		if (!viewOnly) {
			if (e?.target?.valueAsDate) {
				let newDate = dayjs(e?.target?.value).toDate();
				newDate.setFullYear(e?.target?.valueAsDate?.getFullYear());
				updateDate(newDate);
			}
			if (!e?.target?.valueAsDate) {
				updateDate(null);
			}
		}
	};

	const resizeSelectBoxItems = () => {
		let selectboxHeader = dateRef.current;
		let headerStyles = selectboxHeader ? getComputedStyle(selectboxHeader) : null;
		let selectboxList = listRef.current;

		if (selectboxList) {
			selectboxList.style.width = headerStyles ? headerStyles.width : null;
		}
	};
	window.addEventListener("resize", resizeSelectBoxItems);

	useEffect(() => {
		if (!viewOnly) {
			if (!alwaysShowCalendar) {
				const boundingClientRect = listRef.current.getBoundingClientRect();
				const computedStyles = getComputedStyle(listRef.current);
				const height = parseInt(computedStyles.height, 10);
				if (window.innerHeight - height - boundingClientRect.top < 0) {
					listRef.current.style.transform = "translate(0, calc((-100% - 40px)))";
				} else {
					listRef.current.style.transform = null;
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [calendarOpen]);

	const convertDateForInput = (e) => {
		if (e) {
			let dateFromArray = e?.toLocaleString("en-gb")?.split(",")[0];
			let dateSplit = dateFromArray?.split("/");
			dateSplit?.reverse();
			let updatedDateSplit = [
				dateSplit[0]?.padStart(4, "0"),
				dateSplit[1]?.padStart(2, "0"),
				dateSplit[2]?.padStart(2, "0"),
			];
			return updatedDateSplit?.join("-");
		} else {
			return "";
		}
	};

	return (
		<div
			ref={dateRef}
			className={classes.datePicker}
			style={{ position: "relative", ...(customWidth && { width: customWidth }) }}
			onKeyDown={(e) => keyDownCalendar(e)}
		>
			<div
				className={clsx(classes.input, calendarOpen && !viewOnly && classes.active, classes[size])}
				role={"combobox"}
				aria-controls={"dropdown"}
				aria-expanded={calendarOpen}
				style={{ padding: "0px", backgroundColor: viewOnly ? "var(--fill-control-hover)" : "" }}
			>
				<input
					data-testid={automationId}
					name={inputName ?? "datePicker"}
					ref={inputRef}
					aria-label="datePicker"
					className={clsx(classes.date, type && classes[type])}
					type={viewOnly ? "text" : "date"}
					min="1900-01-01"
					max="2191-12-31"
					value={convertDateForInput(date)}
					onChange={handleChange}
					onFocus={() => {
						setCalendarOpen(true);
					}}
					onClick={(e) => {
						e.preventDefault();
						setCalendarOpen(true);
					}}
					onBlur={onBlur}
					style={{ cursor: viewOnly ? "default" : "text" }}
					readOnly={viewOnly}
				/>
			</div>
			{!viewOnly && (
				<div style={{ position: "absolute", zIndex: 5, minWidth: 300 }} ref={listRef}>
					<Calendar
						visible={(calendarOpen && showCalendarDropdown) || alwaysShowCalendar === true}
						date={date}
						setDate={onSelected}
						onSelected={(date) => {
							updateDate(date);
							setCalendarOpen(false);
						}}
						unavailableDates={unavailableDates}
						onMonthViewChange={onMonthViewChange}
						automationId={calendarAutomationId}
					/>
				</div>
			)}
		</div>
	);
};

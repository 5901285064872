import { z } from "zod";

const caseNumberSchema = z.nullable(z.string().max(100, { message: "Case number must be 100 characters or less" }));

const nhsNumberSchema = z.union([
	z
		.string()
		.length(10)
		.refine((val) => validateNHSNumber(val), {
			message: "NHS Number is not valid",
		}),
	z.string().length(0),
]);

export const identifiersSchema = {
	caseNumber: caseNumberSchema,
	nhsNumber: nhsNumberSchema,
};

const validateNHSNumber = (nhsNum) => {
	let checkDigit = 0;
	for (let i = 10; i > 1; i--) {
		checkDigit += Number(nhsNum[10 - i]) * i;
	}
	checkDigit = 11 - (checkDigit % 11);
	checkDigit = checkDigit === 11 ? 0 : checkDigit;

	return !(checkDigit === 10 || Number(nhsNum[9]) !== checkDigit);
};

import classes from "./FormGroup.module.scss";

export const FormGroup = ({ title, children }) => {
	return (
		<div className={classes.group}>
			<div className={classes.headerLine}>
				<p className={classes.title}>{title}</p>
			</div>
			{children}
		</div>
	);
};

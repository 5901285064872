import classes from "./FilterButtons.module.scss";
import { FilterButton } from "./FilterButton/FilterButton";
import { faCalendarDays, faCheck, faHourglassHalf, faUserClock, faXmark } from "@shfortawesome/pro-light-svg-icons";
import {
	faCalendarDays as faCalendarDaysDuo,
	faCheck as faCheckDuo,
	faHourglassHalf as faHourglassHalfDuo,
	faUserClock as faUserClockDuo,
} from "@shfortawesome/pro-duotone-svg-icons";

export const FilterButtons = ({ selectedFilters, toggleFilter, counts, ghost }) => {
	return (
		<div className={classes.FilterButtons}>
			<FilterButton
				primaryText={counts ? counts["Due"] : ""}
				secondaryText="Due"
				icon={faCalendarDays}
				selectedIcon={faCalendarDaysDuo}
				toggleFilter={() => toggleFilter("Due")}
				toggled={selectedFilters?.includes("Due")}
				ghost={ghost}
				automationId="status-button"
			/>
			<FilterButton
				primaryText={counts ? counts["Waiting"] : ""}
				secondaryText="Waiting"
				icon={faUserClock}
				selectedIcon={faUserClockDuo}
				toggleFilter={() => toggleFilter("Waiting")}
				toggled={selectedFilters?.includes("Waiting")}
				ghost={ghost}
				automationId="status-button"
			/>
			<FilterButton
				primaryText={counts ? counts["In progress"] : ""}
				secondaryText="In progress"
				icon={faHourglassHalf}
				selectedIcon={faHourglassHalfDuo}
				toggleFilter={() => toggleFilter("In progress")}
				toggled={selectedFilters?.includes("In progress")}
				ghost={ghost}
				automationId="status-button"
			/>
			<FilterButton
				primaryText={counts ? counts["Completed"] : ""}
				secondaryText="Completed"
				icon={faCheck}
				selectedIcon={faCheckDuo}
				height={"28px"}
				toggleFilter={() => toggleFilter("Completed")}
				toggled={selectedFilters?.includes("Completed")}
				ghost={ghost}
				automationId="status-button"
			/>
			<FilterButton
				primaryText={counts ? counts["Cancelled/DNA"] : ""}
				secondaryText="Cancelled/DNA"
				icon={faXmark}
				selectedIcon={faXmark}
				height={"30px"}
				toggleFilter={() => toggleFilter("Cancelled/DNA")}
				toggled={selectedFilters?.includes("Cancelled/DNA")}
				ghost={ghost}
				automationId="status-button"
			/>
		</div>
	);
};

import { useState } from "react";
import { Button, Textbox } from "@streets-heaver/shui2";
import { FormField } from "../../../FormField/FormField";

export const AdditionalPhoneNumbers = ({
	mainPhone,
	workPhone,
	mainPhoneField,
	workPhoneField,
	mainPhoneIsMandatory = false,
	hasMainPhoneError,
	hasWorkPhoneError,
}) => {
	const [shouldShowMainPhone, setShouldShowMainPhone] = useState(mainPhoneField);
	const [shouldShowWorkPhone, setShouldShowWorkPhone] = useState(workPhoneField);

	return (
		<>
			{(mainPhoneIsMandatory || shouldShowMainPhone) && (
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Main phone" name={mainPhone.name}>
						<Textbox
							type={"filledDarker"}
							inputName={mainPhone.name}
							onChange={mainPhone.onChange}
							onBlur={mainPhone.onBlur}
							reference={mainPhone.ref}
							size="large"
							isError={hasMainPhoneError}
						/>
					</FormField>
				</div>
			)}
			{shouldShowWorkPhone && (
				<div style={{ maxWidth: "300px" }}>
					<FormField label="Work phone" name={workPhone.name}>
						<Textbox
							type={"filledDarker"}
							inputName={workPhone.name}
							onChange={workPhone.onChange}
							onBlur={workPhone.onBlur}
							reference={workPhone.ref}
							size="large"
							isError={hasWorkPhoneError}
						/>
					</FormField>
				</div>
			)}
			<div style={{ display: "flex", gap: "16px" }}>
				{!(shouldShowMainPhone || mainPhoneIsMandatory) && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setShouldShowMainPhone(true);
						}}
						size="small"
					>
						Add main phone
					</Button>
				)}
				{!shouldShowWorkPhone && (
					<Button
						onClick={(e) => {
							e.preventDefault();
							setShouldShowWorkPhone(true);
						}}
						size="small"
					>
						Add work phone
					</Button>
				)}
			</div>
		</>
	);
};

import classes from "./InfoTable.module.scss";
import { useRef } from "react";
import { Card, Table } from "@streets-heaver/shui2";
import { generateTableColumns } from "./columnGeneration";
import clsx from "clsx";
import { NoAccessMessage } from "../../components";

export const InfoTable = ({
	selected,
	onSelectionChange,
	columnData,
	mobileColumnData,
	useMobileColumns,
	data,
	rowCount,
	noDataMessage,
	hasMoreRows,
	fetchNextPage,
	shouldFetchNextPage,
	ghost,
	onRowClick,
	resultCallback,
	shownColumns,
	setShownColumns,
	isExpanding = false,
	ExpandedRowComponent,
	onFiltersClick,
	filtersCount,
	hasPermission = true,
	tableRef,
	automationId,
}) => {
	const innerRef = useRef(null);
	const innerTableRef = tableRef || innerRef;
	const columns = generateTableColumns(
		columnData,
		mobileColumnData,
		useMobileColumns,
		rowCount,
		resultCallback,
		innerTableRef?.current?.offsetWidth,
	);

	if (!hasPermission) {
		return (
			<Card className={classes.noAccessCard}>
				<NoAccessMessage />
			</Card>
		);
	}
	return (
		<div className={clsx(classes.DataTableContainer, ghost && classes.Ghost)} ref={innerTableRef}>
			{ghost ? (
				<Table.Ghost />
			) : (
				<Table
					columns={columns}
					data={data}
					selected={selected}
					onSelectionChange={onSelectionChange}
					noDataMessage={noDataMessage}
					hasMoreRows={hasMoreRows}
					fetchNextPage={fetchNextPage}
					shouldFetchNextPage={shouldFetchNextPage}
					fillContainerHeight
					onRowClick={onRowClick}
					shownColumns={shownColumns}
					setShownColumns={setShownColumns}
					isExpanding={isExpanding}
					ExpandedRowComponent={ExpandedRowComponent}
					onFiltersClick={onFiltersClick}
					filtersCount={filtersCount}
					automationId={automationId}
				/>
			)}
		</div>
	);
};

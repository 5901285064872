import { FormField } from "../../../FormField/FormField";
import { useFormError } from "../../../../../utils";
import { Textbox } from "@streets-heaver/shui2";
import { CountriesLookup } from "../../../lookups/CountriesLookup";

export const Address = ({
	isMandatory = false,
	address,
	addressLine1,
	addressLine2,
	addressLine3,
	townCity,
	county,
	postCode,
	countryFieldName,
}) => {
	return (
		<FormField label="Address" name={address?.name} isMandatory={isMandatory}>
			<Textbox
				type={"filledDarker"}
				inputName={addressLine1.name}
				onChange={addressLine1.onChange}
				onBlur={addressLine1.onBlur}
				reference={addressLine1.ref}
				size="large"
				placeholder="Address line 1"
				isError={useFormError(addressLine1.name).length > 0}
			/>
			<Textbox
				type={"filledDarker"}
				inputName={addressLine2.name}
				onChange={addressLine2.onChange}
				onBlur={addressLine2.onBlur}
				reference={addressLine2.ref}
				size="large"
				placeholder="Address line 2"
				isError={useFormError(addressLine2.name).length > 0}
			/>
			<Textbox
				type={"filledDarker"}
				inputName={addressLine3.name}
				onChange={addressLine3.onChange}
				onBlur={addressLine3.onBlur}
				reference={addressLine3.ref}
				size="large"
				placeholder="Address line 3"
				isError={useFormError(addressLine3.name).length > 0}
			/>
			<Textbox
				type={"filledDarker"}
				inputName={townCity.name}
				onChange={townCity.onChange}
				onBlur={townCity.onBlur}
				reference={townCity.ref}
				size="large"
				placeholder="Town/City"
				isError={useFormError(townCity.name).length > 0}
			/>
			<Textbox
				type={"filledDarker"}
				inputName={county.name}
				onChange={county.onChange}
				onBlur={county.onBlur}
				reference={county.ref}
				size="large"
				placeholder="County"
				isError={useFormError(county.name).length > 0}
			/>
			<Textbox
				type={"filledDarker"}
				inputName={postCode.name}
				onChange={postCode.onChange}
				onBlur={postCode.onBlur}
				reference={postCode.ref}
				size="large"
				placeholder="Postcode"
				isError={useFormError(postCode.name).length > 0}
			/>
			<CountriesLookup name={countryFieldName} inline />
		</FormField>
	);
};

import { EpisodeOfCareCard } from "../../EpisodeOfCareCard/EpisodeOfCareCard";
import { PatientItem } from "@streetsheaver/compucore";
import classes from "./AppointmentForm.module.scss";
import { CancellationReasonsLookup } from "../lookups/CancellationReasonsLookup";
import { OutcomesLookup } from "../lookups/OutcomesLookup";

export const CancelAppointmentForm = () => {
	return (
		<div className={classes.appointmentForm}>
			<div className={classes.header}>
				<p>You are about to cancel the following booking:</p>
				<div className={classes.patient}>
					<PatientItem />
				</div>
				<EpisodeOfCareCard />
			</div>
			<p>Please fill out the following reason and outcome.</p>
			<CancellationReasonsLookup isMandatory />
			<OutcomesLookup />
		</div>
	);
};

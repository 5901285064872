import React from "react";
import ReactDOM from "react-dom/client";
import "@streets-heaver/shui2/SHUI2Styles.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { MsalProvider } from "@azure/msal-react";
import App from "./App";
import { msalInstance } from "./authConfig";
import "./dayjsSetup";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<MsalProvider instance={msalInstance}>
				<div id={"modal-portal-exit"}></div>
				<App />
			</MsalProvider>
		</QueryClientProvider>
	</React.StrictMode>,
);

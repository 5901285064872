import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./NoAccessMessage.module.scss";
import { Button } from "@streets-heaver/shui2";
import { faLockKeyhole } from "@shfortawesome/pro-duotone-svg-icons";

export const NoAccessMessage = ({ style }) => {
	return (
		<div className={classes.noAccess}>
			<div className={classes.content}>
				<FontAwesomeIcon icon={faLockKeyhole} className={classes.keyhole} />
				<div className={classes.textContainer}>
					<p className={classes.title}>No access</p>
					<p className={classes.message}>
						You do not have permission to access this.
						<br />
						Please contact your admin.
					</p>
				</div>
			</div>
			{style === "page" && (
				<div>
					<Button
						className={classes.button}
						type="primary"
						navigation={{ to: "https://compucare.streets-heaver.com/home" }}
					>
						Return to the home screen
					</Button>
				</div>
			)}
		</div>
	);
};

import { Datebox, Dropdown, Lookup, Switch, Timebox } from "@streets-heaver/shui2";
import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { useContactTypesLookup } from "../../../../../api/hooks";

export const Booking = () => {
	const form = useFormContext();

	const dateName = "date";
	const timeName = "time";
	const durationName = "duration";
	const isFaceToFaceName = "isFaceToFace";
	const contactTypeName = "contactType";

	const contactTypeQuery = useContactTypesLookup();

	return (
		<FormGroup title="Booking">
			<FormField label="Date" name={dateName} isMandatory>
				<Controller
					name={dateName}
					control={form.control}
					render={({ field: { value, onChange } }) => (
						<Datebox
							customWidth={160}
							size="large"
							type={"filledDarker"}
							date={value}
							showInitialDate={typeof value !== "undefined"}
							onSelected={(e) => onChange(e)}
						/>
					)}
				/>
			</FormField>
			<FormField label="Time" name={timeName} isMandatory>
				<Controller
					name={timeName}
					control={form.control}
					render={({ field: { onChange, value } }) => (
						<Timebox
							type="filledDarker"
							size="large"
							customWidth={160}
							interval={5}
							defaultTime={value}
							setValue={onChange}
						/>
					)}
				/>
			</FormField>
			<div style={{ width: 200 }}>
				<FormField label="Duration" name={durationName} isMandatory>
					<Controller
						name={durationName}
						render={({ field: { value, onChange } }) => (
							<Dropdown
								placeholder={{ label: "Select a duration" }}
								value={value}
								onChange={onChange}
								type={"filledDarker"}
								size="large"
								options={durationOptions}
							/>
						)}
					/>
				</FormField>
			</div>
			<FormField label="Is face to face?" isMandatory name={isFaceToFaceName}>
				<Controller
					control={form.control}
					name={isFaceToFaceName}
					render={({ field: { onChange, value } }) => <Switch isOn={value} handleToggle={onChange} />}
				/>
			</FormField>
			{!form.watch(isFaceToFaceName) && (
				<div style={{ width: 340 }}>
					<FormField label="Contact type" name={contactTypeName} isMandatory>
						<Controller
							name={contactTypeName}
							control={form.control}
							render={({ field: { value, onChange } }) => (
								<Lookup
									dropdownMaxHeight={"calc(80vh - 190px)"}
									selected={value}
									setSelected={onChange}
									searchQueryData={contactTypeQuery}
								/>
							)}
						/>
					</FormField>
				</div>
			)}
		</FormGroup>
	);
};

const durationOptions = [
	{ label: "5 mins", value: 5 },
	{ label: "10 mins", value: 10 },
	{ label: "15 mins", value: 15 },
	{ label: "20 mins", value: 20 },
	{ label: "25 mins", value: 25 },
	{ label: "30 mins", value: 30 },
];

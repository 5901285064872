import { Divider } from "@streets-heaver/shui2";
import { Name } from "./Sections/Name/Name";
import { Identity } from "./Sections/Identity/Identity";
import { Identifiers } from "./Sections/Identifiers/Identifiers";
import { Contact } from "./Sections/Contact/Contact";
import { NextOfKin } from "./Sections/NextOfKin/NextOfKin";
import { Shared } from "./Sections/Shared/Shared";
import { useFormContext } from "react-hook-form";

export const AddPatientForm = () => {
	const methods = useFormContext();

	const title = methods.register("name.primary.title");
	const surname = methods.register("name.primary.surname");
	const forename = methods.register("name.primary.forename");

	return (
		<div style={{ display: "flex", gap: "32px", flexDirection: "column" }}>
			<Name title={title} surname={surname} forename={forename} />
			<Divider />
			<Identity />
			<Divider />
			<Contact />
			<Divider />
			<Identifiers />
			<Divider />
			<NextOfKin />
			<Divider />
			<Shared />
		</div>
	);
};

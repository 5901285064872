import { z } from "zod";

export const cancelAppointment = z.object({
	outcome: z.nullable(z.object({ recordId: z.union([z.number(), z.string()]) })).optional(),
	cancellationReason: z.object({ recordId: z.union([z.number(), z.string()]) }, { message: "Cancellation reason is required" }),
});

export const addCancelAppointmentValues = () => ({
	outcome: null,
	cancellationReason: null,
});

import { useRegisteredGPs } from "../../../../../api/hooks";
import { Controller, useFormContext } from "react-hook-form";
import { Lookup } from "@streets-heaver/shui2";
import { FormGroup } from "../../../FormGroup/FormGroup.jsx";
import { FormField } from "../../../FormField/FormField.jsx";
import { PracticesLookup } from "../../../lookups/PracticesLookup.jsx";

export const Shared = () => {
	const form = useFormContext();

	const registeredGPQuery = useRegisteredGPs();

	const registeredGPName = "sharedDetails.registeredGP";
	const gpPracticeName = "sharedDetails.gpPractice";
	const registeredOpticianName = "sharedDetails.registeredOptician";
	const opticianPracticeName = "sharedDetails.opticianPractice";

	return (
		<div data-section id="shared">
			<FormGroup title="Shared details">
				<FormField label="Registered GP">
					<Controller
						name={registeredGPName}
						control={form.control}
						render={({ field: { value, onChange } }) => (
							<Lookup
								dropdownMaxHeight={"calc(80vh - 190px)"}
								selected={value}
								setSelected={onChange}
								searchQueryData={registeredGPQuery}
							/>
						)}
					/>
				</FormField>
				<PracticesLookup label="GP practice" name={gpPracticeName} />
				<FormField label="Registered optician">
					<Controller
						name={registeredOpticianName}
						control={form.control}
						render={({ field: { value, onChange } }) => (
							<Lookup
								dropdownMaxHeight={"calc(80vh - 190px)"}
								selected={value}
								setSelected={onChange}
								searchQueryData={registeredGPQuery}
							/>
						)}
					/>
				</FormField>
				<PracticesLookup label="Optician practice" name={opticianPracticeName} />
			</FormGroup>
		</div>
	);
};

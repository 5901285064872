import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faCalendarDays, faClipboardList, faUsersRectangle } from "@shfortawesome/pro-duotone-svg-icons";

const iconStyle = { height: 20, width: 20, padding: "2px", marginRight: 4, color: "var(--appColour)" };

export const breadcrumbs = (pathname, dataSourceId) => {
	const pagename = pathname?.split("/")?.[2];
	const siteObject = {
		name: "Clinician",
		icon: <FontAwesomeIcon icon={faUserDoctor} style={iconStyle} />,
		navigation: { to: "/" + dataSourceId },
	};
	let arrayOfBreadcrumbs = [];

	arrayOfBreadcrumbs.push(siteObject);

	switch (pagename) {
		case "scheduler":
			arrayOfBreadcrumbs.push({
				name: "Scheduler",
				icon: <FontAwesomeIcon icon={faCalendarDays} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "scheduler" },
			});
			break;
		case "mypatients":
			arrayOfBreadcrumbs.push({
				name: "My patients",
				icon: <FontAwesomeIcon icon={faUsersRectangle} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "mypatients" },
			});
			break;
		case "orders":
			arrayOfBreadcrumbs.push({
				name: "Orders",
				icon: <FontAwesomeIcon icon={faClipboardList} style={iconStyle} />,
				navigation: { to: "/" + dataSourceId + "/" + "orders" },
			});
			break;
		default:
			break;
	}

	return arrayOfBreadcrumbs;
};

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useForm, FormProvider } from "react-hook-form";
import { EditPatientForm, FormWrapper, DiscardChangesDialog } from "../../components";
import { ActionPaths } from "../../layouts/Layout/actionPaths";
import { PatientDummyData } from "../../api/patientDummyData";
import { addPatientFormDefaultValues, addPatientSchema } from "../AddPatient/AddPatientSchema";
import { PatientLookup } from "@streetsheaver/compucore";
import { zodResolver } from "@hookform/resolvers/zod";
import toast from "react-hot-toast";
import { usePatientsLookup } from "../../api/hooks";

export const EditPatient = () => {
	const { patientId } = useParams();
	const patientsQuery = usePatientsLookup();

	// TODO: Change PatientDummyData[0] to API result of patient endpoint
	const selectedPatient = patientId && PatientDummyData[0];

	const [isConfirmDiscardDialogVisible, setConfirmDiscardDialogVisible] = useState(false);
	const navigate = useNavigate();
	const methods = useForm({
		mode: "onTouched",
		criteriaMode: "all",
		defaultValues: { ...addPatientFormDefaultValues(), ...selectedPatient },
		resolver: zodResolver(addPatientSchema),
	});

	useEffect(() => {
		methods.reset({ ...addPatientFormDefaultValues(), ...selectedPatient });
	}, [methods, selectedPatient]);

	const onSubmit = (data) => {
		toast.promise(
			new Promise((resolve) => {
				setTimeout(() => resolve(), 2000);
			}),
			{
				loading: "Editing patient",
				error: "Error editing patient",
				success: "Edited patient",
			},
		);
		console.log("edit patient data", data);
		navigate(-2);
	};

	const onClose = () => navigate(-2);

	return (
		<>
			<FormProvider {...methods}>
				<FormWrapper
					header={"Edit Patient"}
					buttons={{
						primaryButton: {
							onClick: methods.handleSubmit(onSubmit),
							children: "Save",
						},
						secondaryButton: {
							onClick: () => {
								selectedPatient ? setConfirmDiscardDialogVisible(true) : navigate(-2);
							},
							children: "Cancel",
						},
					}}
				>
					{selectedPatient ? (
						<FormWrapper.Body>
							<EditPatientForm />
						</FormWrapper.Body>
					) : (
						<FormWrapper.Body>
							<PatientLookup
								field={{ label: "Patient", isMandatory: true }}
								selected={selectedPatient}
								patientLinkPrefix={"../patient/"}
								setSelected={(patient) =>
									navigate(patient?.patientId ? `./${patient.patientId}` : `../${ActionPaths.EditPatient}`)
								}
								dropdownMaxHeight={"calc(80vh - 190px)"}
								searchQueryData={patientsQuery}
							/>
						</FormWrapper.Body>
					)}
				</FormWrapper>
			</FormProvider>
			<DiscardChangesDialog
				onClose={onClose}
				visible={isConfirmDiscardDialogVisible}
				setVisible={setConfirmDiscardDialogVisible}
			/>
		</>
	);
};

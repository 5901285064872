import classes from "./Radio.module.scss";
import clsx from "clsx";

export const Radio = ({ items, value, onChange, isError, automationId, viewOnly, disabled }) => {
	return (
		<div
			className={clsx(
				classes.items,
				isError && classes["--error"],
				disabled && classes["--disabled"],
				viewOnly && classes["--viewOnly"],
			)}
			data-testid={automationId}
		>
			{items.map((item) => (
				<label className={classes.item} key={item.value} data-testid={`${automationId}-${item.value}`}>
					<div className={classes.checkMarkContainer}>
						<input
							type="radio"
							checked={item.value === value}
							onChange={() => onChange?.(item)}
							data-testid={`${automationId}-${item.value}-checkMark`}
							disabled={disabled || viewOnly}
						/>
						<span className={classes.checkMark}></span>
					</div>
					<div className={classes.content}>{item.label}</div>
				</label>
			))}
		</div>
	);
};

export default Radio;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./SwipeInteraction.module.scss";
import { motion, useAnimate } from "framer-motion";
import { useRef, useState } from "react";
import { useOutsideAlerter } from "../../utils/hooks/useOutsideAlerter";

export const SwipeInteraction = ({ enabled, onSwipeAction, actionText, actionIcon, children }) => {
    const [containerScope, containerAnimate] = useAnimate();
    const [spaceScope, spaceAnimate] = useAnimate();
    const outsideRef = useRef();
    const [isHalfSwiped, setIsHalfSwiped] = useState(false);
    
    function cancelSwipe() {
		if (enabled) {
			setIsHalfSwiped(false);
			containerAnimate(containerScope.current, { x: 0 }, { type: 'tween' });
			spaceAnimate(spaceScope.current, {width: 0}, {type: 'tween'});
		}
    }
    
    function fullSwipe() {
        onSwipeAction();
        containerAnimate(containerScope.current, { x: -300 }, { type: 'tween' });
        spaceAnimate(spaceScope.current, { width: 300 }, { type: "tween" });
        
        setTimeout(() => {
            cancelSwipe();
        }, 1500);
    }
    
    useOutsideAlerter(outsideRef, cancelSwipe);

	if (!enabled) return children;

	return (
		<div ref={outsideRef}>
			<motion.div
				ref={containerScope}
				drag="x"
				dragConstraints={{ left: -300, right: 0 }}
				dragElastic={0}
				onDrag={(event, info) => {
					spaceAnimate(
						spaceScope.current,
						{ width: Math.min(-(isHalfSwiped ? info.offset.x - 60 : info.offset.x), 300) },
						{ duration: 0 },
					);
				}}
				onDragEnd={(event, info) => {
					if (-info.offset.x > 200) {
						fullSwipe();
					} else if (-info.offset.x > 40) {
						setIsHalfSwiped(true);
						containerAnimate(containerScope.current, { x: -60 }, { type: "tween" });
						spaceAnimate(spaceScope.current, { width: 60 }, { type: "tween" });
					} else {
						cancelSwipe();
					}
				}}
			>
				{children}
			</motion.div>
			<motion.div className={classes.swipeSpace} ref={spaceScope} onClick={fullSwipe}>
				<FontAwesomeIcon icon={actionIcon} />
				<p>{actionText}</p>
			</motion.div>
		</div>
	);
};
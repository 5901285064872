import dayjs from "dayjs";
import { useQuery } from "@tanstack/react-query";
import { makeApiRequest } from "../makeApiRequest";
import { useCurrentClinicians } from "./useClinicians";
import { useDataSources } from "@streetsheaver/compucore";
import { localFromUTC } from "../../utils";

export const useSchedulerAvailability = (startDate, endDate, enabled = true) => {
	const { currentDataSource } = useDataSources();
	const { currentClinician } = useCurrentClinicians();
	const results = useQuery({
		queryKey: ["schedulerAvailability", currentClinician, startDate, endDate],
		queryFn: async () => {
			const data = await makeApiRequest(
				`${currentDataSource?.DataSourceId}/clinician/${currentClinician}/scheduler/availability?startDate=${startDate}&endDate=${endDate}`,
			);
			if (data?.request?.response) throw new Error("Getting scheduler availability Failed");
			let groupedAvailability = [];
			data?.availabilities.map((availability, i) => {
				let availabilityStart = localFromUTC(availability?.start);
				let availabilityEnd = localFromUTC(availability?.end);
				if (i < 1) {
					groupedAvailability.push({
						data: [{ ...availability, start: availabilityStart, end: availabilityEnd }],
						date: availabilityStart.format("DD-M-YYYY"),
					});
				} else {
					let previousEnd = localFromUTC(data[i - 1]?.end);
					if (availabilityStart.isSame(previousEnd, "day")) {
						groupedAvailability[groupedAvailability.length - 1]?.data.push({
							...availability,
							start: availabilityStart,
							end: availabilityEnd,
						});
					} else {
						groupedAvailability.push({
							data: [{ ...availability, start: availabilityStart, end: availabilityEnd }],
							date: availabilityStart.format("DD-M-YYYY"),
						});
					}
				}
			});
			return { groupedAvailability, surgical24h: data?.surgical24h, outpatient24h: data?.outpatient24h };
		},
		staleTime: Infinity,
		enabled: !!currentDataSource && !!currentClinician && !!startDate && !!endDate && enabled,
	});

	return results;
};

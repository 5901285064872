import { Controller, useFormContext } from "react-hook-form";
import { FormGroup } from "../../../FormGroup/FormGroup";
import { FormField } from "../../../FormField/FormField";
import { EpisodeOfCareLookup } from "@streetsheaver/compucore";
import { useEpisodeOfCaresLookup } from "../../../../../api/hooks";
import { PatientsLookup } from "../../../lookups/PatientsLookup";

export const FollowUpPatient = () => {
	const form = useFormContext();

	const patientName = "patient";
	const episodeOfCareName = "episodeOfCare";

	const episodeOfCareQuery = useEpisodeOfCaresLookup(
		form.watch(patientName) ? "25d64491-1cda-441d-9995-a57c86551c57" : undefined,
	);

	return (
		<FormGroup title="Patient" >
			<PatientsLookup isMandatory name={patientName} />
			<FormField label="Episode of care" isMandatory name={episodeOfCareName}>
				<Controller
					name={episodeOfCareName}
					control={form.control}
					render={({ field: { value, onChange } }) => (
						<EpisodeOfCareLookup
							selected={value}
							setSelected={onChange}
							dropdownMaxHeight={"calc(80vh - 190px)"}
							disabled={!form.watch(patientName)}
							searchQueryData={episodeOfCareQuery}
						/>
					)}
				/>
			</FormField>
		</FormGroup>
	);
};

import classes from "./NextPatientCard.module.scss";
import { Card } from "@streets-heaver/shui2";
import { useNextPatient } from "../../api/hooks/useNextPatient";
import { PatientCardNextPatient } from "../PatientCard/PatientCard";
import { GroupBookingCard } from "../GroupBookingCard/GroupBookingCard";
import { NoAccessMessage } from "../../components";

export const NextPatientCard = ({ hasPermission }) => {
	const nextPatient = useNextPatient(hasPermission);

	if (!hasPermission) {
		return (
			<Card className={classes.nextPatientCard}>
				<NoAccessMessage />
			</Card>
		);
	}

	return (
		<Card className={classes.nextPatientCard}>
			{nextPatient?.error ? (
				<p className={classes.noBookings}>Error checking for future bookings.</p>
			) : nextPatient?.isLoading || nextPatient?.data ? (
				nextPatient?.data?.type === "OPGB" ? (
					<div className={classes.groupBooking}>
						<GroupBookingCard groupBookingInfo={nextPatient} noPatientTabs />
					</div>
				) : (
					<PatientCardNextPatient
						ghost={nextPatient.isLoading}
						appointment={nextPatient?.data}
						bookingUniqueId={nextPatient?.isLoading ? null : nextPatient?.data?.bookingUniqueId}
						automationId={"nextpatientcard"}
					/>
				)
			) : (
				<p className={classes.noBookings}>No future bookings found.</p>
			)}
		</Card>
	);
};

import classes from "./PatientNameAndID.module.scss";
import { formatNameAsReversed, getStatusColour } from "../../../../utils";
import { useDataSources } from "@streetsheaver/compucore";
import { NavLink } from "@streets-heaver/shui2";

export const PatientNameAndId = ({ details }) => {
	const { currentDataSource } = useDataSources();
	return (
		<div className={classes.PatientNameAndId} data-testid="table-patient-name">
			{details?.status && (
				<div className={classes.Badge} style={{ backgroundColor: getStatusColour(details?.status) }} />
			)}
			<span className={classes.Name}>{formatNameAsReversed(details?.forename, details?.surname, details?.title)}</span>
			<NavLink type={"default"} to={`/${currentDataSource?.DataSourceId}/patient/${details?.patientId}`} canCopy>
				{details?.patientId}
			</NavLink>
		</div>
	);
};

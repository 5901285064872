import { z } from "zod";

const purchaserLookupSchema = z.nullable(z.object({ purchaserId: z.union([z.number(), z.string()]) }));
const authCodeSchema = z.string().max(50, "Authorisation Code must be 50 characters or less").optional();
const membershipNumberSchema = z
	.string()
	.max(40, "Insurance Membership Number must be 40 characters or less")
	.optional();

export const purchaserSchema = {
	purchaser: purchaserLookupSchema,
	authCode: authCodeSchema,
	membershipNumber: membershipNumberSchema,
};

export const purchaserMembershipNumberRefinement = {
	condition: (val) => !(val.purchaser !== null && val.membershipNumber === ""),
	args: {
		message: "Insurance membership number is required when a purchaser is selected",
		path: ["membershipNumber"],
	},
};

import { z } from "zod";

const titleSchema = z.string().max(25, { message: "Title must be 25 characters or less" });

const forenameSchema = z.string().max(200, { message: "Given name must be 200 characters or less" });

const surnameSchema = z
	.string()
	.min(1, { message: "Family name is required and cannot be left blank." })
	.max(200, { message: "Family name must be 200 characters or less" });

export const nameSchema = { title: titleSchema, forename: forenameSchema, surname: surnameSchema };

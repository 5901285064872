import { LogLevel, PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
	auth: {
		clientId: import.meta.env.VITE_MSALCLIENTID,
		authority: "https://login.microsoftonline.com/common",
		redirectUri: import.meta.env.BASE_URL,
	},
	cache: {
		cacheLocation: "localStorage",
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						// eslint-disable-next-line no-console
						console.error(message);
						return;
					default:
						return;
				}
			},
		},
	},
};

export const msalInstance = new PublicClientApplication(msalConfig);

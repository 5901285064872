import { useState, useRef } from "react";
import { Divider, TabList } from "@streets-heaver/shui2";
import { Name } from "./Sections/Name/Name";
import { Identity } from "./Sections/Identity/Identity";
import { Contact } from "./Sections/Contact/Contact";
import { Identifiers } from "./Sections/Identifiers/Identifiers";
import { NextOfKin } from "./Sections/NextOfKin/NextOfKin";
import { Shared } from "./Sections/Shared/Shared";
import { faArrowUp } from "@shfortawesome/pro-light-svg-icons";
import { FloatingActionButton } from "../../FloatingActionButton/FloatingActionButton";
import { useFormContext } from "react-hook-form";

export const EditPatientForm = () => {
	const containerRef = useRef(null);
	const form = useFormContext();
	const [selected, setSelected] = useState();

	const title = form.register("title");
	const surname = form.register("surname");
	const forename = form.register("forename");

	const handleScroll = () => {
		const sections = containerRef.current.querySelectorAll("[data-section]");
		let groupArray = [];
		sections.forEach((section) => {
			groupArray.push({
				name: section.id,
				positionTop: section.getBoundingClientRect().top,
				positionBottom: section.getBoundingClientRect().bottom,
			});
		});

		function findIndex() {
			let smallestPositive = 0;
			for (let i = 0; i < groupArray.length; i++) {
				if (groupArray[i].positionTop < 0) {
					smallestPositive++;
				}
			}
			return groupArray[smallestPositive];
		}
		const one = findIndex().name;
		setSelected(one);
	};

	const scrollTab = (e) => {
		const section = document.getElementById(e);
		section.scrollIntoView();
	};

	const tabItemsText = [
		{ label: "Name", value: "name" },
		{ label: "Identity", value: "identity" },
		{ label: "Contact", value: "contact" },
		{ label: "Identifiers", value: "identifiers" },
		{ label: "Next of kin", value: "nextOfKin" },
		{ label: "Shared", value: "shared" },
	];

	const formSections = [
		{ component: <Name title={title} forename={forename} surname={surname} />, section: "name" },
		{ component: <Identity />, section: "identity" },
		{ component: <Contact />, section: "contact" },
		{ component: <Identifiers />, section: "identifiers" },
		{ component: <NextOfKin />, section: "nextOfKin" },
		{ component: <Shared />, section: "shared" },
	];
	return (
		<div data-section id="heading">
			<TabList
				tabs={tabItemsText}
				updateSelectedTab={selected}
				onChange={(e) => {
					scrollTab(e);
					setSelected(e);
				}}
				fitContainer
				selected={selected}
				setSelected={setSelected}
			/>
			<div
				style={{ display: "flex", flexDirection: "column", width: "100%" }}
				ref={containerRef}
				onScroll={handleScroll}
			>
				{formSections.map((section, index) => {
					return (
						<div id={section.section} key={index}>
							{section.component}
							{index < formSections.length - 1 && <Divider />}
						</div>
					);
				})}
			</div>
			<FloatingActionButton
				icon={{ restIcon: faArrowUp }}
				onClick={(e) => {
					e.preventDefault();
					scrollTab("heading");
				}}
			/>
		</div>
	);
};

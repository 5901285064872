export const OrdersColumns = [
	{
		displayName: "Orders",
		accessorKey: "orderPatientNameAndId",
	},
	{
		displayName: "Service request",
		accessorKey: "request",
	},
	{
		displayName: "Seen",
		accessorKey: "isRead",
	},
	{
		displayName: "Results",
		accessorKey: "results",
	},
];

export const OrdersMobileColumns = [
	{
		displayName: "Orders",
		accessorKey: "mobileOrderColumn",
	},
];

export const orderWidgetDummyData = [
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",

		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		seen: true,
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: false,
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		seen: true,
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		seen: true,
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		// Orders
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		seen: true,
		// Orders
		completionDate: null,
		orderStatusId: "int NOT NULL",
		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
	{
		// ServiceRequests
		serviceRequestId: "int IDENTITY(1,1) NOT NULL",
		serviceRequestName: "varchar(200) NOT NULL",
		reportable: "bit NOT NULL",
		seen: true,
		// Orders
		completionDate: "datetime",
		orderStatusId: "int NOT NULL",
		verifiedDate: new Date("2021-06-09T11:06:23.797"),

		hasResult: true, //"bool", Calculated response based on OrderFormResults row existing for linked order
	},
];

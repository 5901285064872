import { faChevronDown, faChevronUp } from "@shfortawesome/pro-light-svg-icons";
import { faChevronDown as farChevronDown, faChevronUp as farChevronUp } from "@shfortawesome/pro-regular-svg-icons";
import { faChevronDown as fasChevronDown, faChevronUp as fasChevronUp } from "@shfortawesome/pro-solid-svg-icons";
import { Button } from "@streets-heaver/shui2";

export const MinimiseButton = ({ minimise, setMinimise }) => {
	return (
		<Button
			type="transparent"
			colour="grey"
			onClick={(e) => {
				e.stopPropagation();
				e.preventDefault();
				setMinimise(!minimise);
			}}
			icon={{
				restIcon: minimise ? faChevronDown : faChevronUp,
				hoverIcon: minimise ? farChevronDown : farChevronUp,
				pressedIcon: minimise ? fasChevronDown : fasChevronUp,
			}}
			ariaLabel="Expand row"
		/>
	);
};

import toast from "react-hot-toast";
import { shortDateFormat } from "../../utils";
import dayjs from "dayjs/esm";

export function validateFilters(filters, showToast = true) {
	const filtersToApply = {...filters};

	if (!filtersToApply.start.value?.length && !filtersToApply.end.value?.length ) {
		filtersToApply.start = filtersToApply.start.cloneWithNewValue([dayjs().startOf("day").format()]);
		filtersToApply.end = filtersToApply.end.cloneWithNewValue([dayjs().endOf("day").format()]);

		if (showToast) {
			toast.error({
				title: "No date range",
				subtitle: `The 'To' and 'From' date filters have been set to today.`,
			});
		}
	} else {
		const fromDateToApply = filtersToApply.start;
		const toDateToApply = filtersToApply.end;

		if (new Date(toDateToApply.value[0]) < new Date(fromDateToApply.value[0])) {
			filtersToApply.end = filtersToApply.end.cloneWithNewValue(fromDateToApply.value)

			toast.error({
				title: "Invalid date range",
				subtitle: `The 'To' date filter has been set to ${shortDateFormat(filtersToApply.end.value[0])}.`,
			});
		}
	}

	return filtersToApply;
}
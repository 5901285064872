import { ActionPaths } from "./actionPaths";
import { faPencil, faPlus, faPrint } from "@shfortawesome/pro-light-svg-icons";

export const newButton = (
	disabled = false,
	primaryTo = ActionPaths.AddAppointment,
	primaryText = "New Appointment",
) => {
	return {
		type: "primary",
		icon: { restIcon: faPlus },
		navigation: { to: primaryTo },
		disabled: disabled,
		menuProps: {
			hasIcons: true,
			hasChevron: true,
			items: [
				{
					items: [
						{
							text: "Create new appointment",
							hasChevron: true,
							to: ActionPaths.AddFullAppointment,
						},
						{
							text: "Create follow up appointment",
							hasChevron: true,
							to: ActionPaths.AddFollowUpAppointment,
						},
						{
							text: "Create new patient",
							hasChevron: true,
							to: ActionPaths.AddPatient,
						},
					],
				},
			],
		},
		noChevron: true,
		children: primaryText,
		menuAriaLabel: "Open 'add new' menu",
		automationId: "newAppointmentButton",
	};
};

export const editButton = (disabled = false, primaryTo = ActionPaths.EditAppointment, primaryText = "Edit") => {
	return {
		icon: { restIcon: faPencil },
		navigation: { to: primaryTo },
		disabled: disabled,
		menuProps: {
			hasIcons: true,
			hasChevron: true,
			items: [
				{
					items: [
						{
							text: "Edit appointment",
							hasChevron: true,
							to: ActionPaths.EditAppointment,
						},
						{
							text: "Cancel appointment",
							hasChevron: true,
							to: ActionPaths.CancelAppointment,
						},
						{
							text: "Edit patient",
							hasChevron: true,
							to: ActionPaths.EditPatient,
						},
					],
				},
			],
		},
		children: primaryText,
		menuAriaLabel: "Open 'edit' menu",
		automationId: "editAppointmentButton",
	};
};

export const printButton = (disabled = false, modalPrefix, ordersCount, onOrdersPrintClick) => {
	return {
		icon: { restIcon: faPrint },
		navigation: { to: `${modalPrefix ? `${modalPrefix}/` : ""}${ActionPaths.PrintModalClinic}` },
		children: "Print lists",
		disabled: disabled,
		automationId: "printButton",
		menuAriaLabel: "Open 'print lists' menu",
		menuProps: {
			hasIcons: true,
			hasChevron: true,
			items: [
				{
					items: [
						{
							text: "Clinic list",
							hasChevron: true,
							to: `${modalPrefix ? `${modalPrefix}/` : ""}${ActionPaths.PrintModalClinic}`,
						},
						{
							text: "Surgical list",
							hasChevron: true,
							to: `${modalPrefix ? `${modalPrefix}/` : ""}${ActionPaths.PrintModalSurgical}`,
						},
					],
				},
				...(onOrdersPrintClick
					? [
							{
								header: "Orders",
								items: [
									{
										text: `Print selected orders (${ordersCount})`,
										onClick: onOrdersPrintClick,
										isDisabled: ordersCount < 1,
									},
								],
							},
						]
					: []),
			],
		},
	};
};

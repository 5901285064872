import classes from "./FormResultsHistoryModal.module.scss";
import { Sheet } from "@streetsheaver/compucore";
import { useNavigate, useParams } from "react-router";
import { useOrderFormResults } from "../../api/hooks/useOrderFormResults";
import { useEffect, useMemo, useRef } from "react";
import { ExpandedRequestRow } from "../../components/RequestCard/RequestCard";
import { TruncatedText } from "../../components/TruncatedText/TruncatedText";
import { dateTimeFormat } from "../../utils";
import { ActionPaths } from "../../layouts/Layout/actionPaths";

export const FormResultsHistoryModal = () => {
	const navigate = useNavigate();

	return (
		<Sheet visible onClose={() => navigate("..")} header={{ title: "Form Results History" }}>
			<ResultsTable />
		</Sheet>
	);
};

export const ResultsTable = () => {
	const { patientId, formComponentId } = useParams();
	const {
		data: resultData,
		fetchNextPage,
		isFetching,
		hasNextPage,
	} = useOrderFormResults(patientId, formComponentId, 10);

	const endOfListRef = useRef(null);

	useEffect(() => {
		const observer = new IntersectionObserver((elements) => {
			elements.forEach((element) => {
				if (element.isIntersecting && !isFetching && hasNextPage) {
					fetchNextPage();
				}
			});
		});

		if (endOfListRef.current) observer.observe(endOfListRef.current);

		return () => {
			observer.disconnect();
		};
	}, [fetchNextPage, isFetching, hasNextPage, resultData]);

	const data = useMemo(
		() => ({
			...resultData?.pages[0].data,
			results: resultData?.pages.reduce((prev, curr) => prev.concat(curr.data.results), []),
		}),
		[resultData],
	);

	return (
		<div className={classes.requestCard} data-testid={"results-history"}>
			<div className={classes.header}>
				<TruncatedText>{data?.name}</TruncatedText>
				{data?.completionDate && <p>{dateTimeFormat(data?.completionDate)}</p>}
			</div>
			<div className={classes.body}>
				{data?.results?.map((request, i) => (
					<ExpandedRequestRow
						request={request}
						to={ActionPaths.FormHistoryResults(request?.formComponentId)}
						key={`request-${i}`}
					/>
				))}
				<div ref={endOfListRef} />
				{hasNextPage && <ExpandedRequestRow ghost />}
			</div>
		</div>
	);
};

import { PatientNameAndId, MobileBookingCell, MobileOrderCell, TableTitle, ServiceRequests } from "./cellComponents";
import { TruncatedArray } from "../TruncatedArray/TruncatedArray";
import { shortDateFormat, timeFormat, durationFromDates, localFromUTC } from "../../utils";
import { Link } from "../Link/Link";
import { PatientSeenRequest } from "./cellComponents/BadgeCells/BadgeCells";

export const generateTableColumns = (
	columns,
	mobileColumns,
	useMobileColumns,
	rowCount,
	resultCallback,
	tableWidth,
) => {
	const columnsData = useMobileColumns ? mobileColumns?.data : columns?.data;
	const columnWidths = useMobileColumns ? mobileColumns?.widths : columns?.widths;
	return columnsData.map((column) => ({
		...column,
		id: column?.displayName,
		header: column?.displayName,
		...generateColumn(column, rowCount, resultCallback, tableWidth),
		...(columnWidths?.[column?.displayName] && { size: columnWidths?.[column?.displayName] }),
	}));
};

export const generateColumn = (column, rowCount, resultCallback, tableWidth) => {
	const patientColumnSize = 340;
	const calculatedRequestsWidth = tableWidth - patientColumnSize - 80 - 60 - 40 - 20;

	switch (column?.accessorKey) {
		case "mobileBookingColumn":
			return {
				header: ({ header }) => <TableTitle details={{ title: header.id, number: rowCount }} />,
				cell: ({ row }) => <MobileBookingCell row={row} />,
				minSize: 280,
				enableResizing: false,
			};
		case "mobileOrderColumn":
			return {
				header: ({ header }) => <TableTitle details={{ title: header.id, number: rowCount }} />,
				cell: ({ row }) => <MobileOrderCell row={row} resultCallback={resultCallback} />,
				minSize: 280,
				enableResizing: false,
			};
		case "patientNameAndId":
			return {
				header: ({ header }) => <TableTitle details={{ title: header.id, number: rowCount }} />,
				cell: ({ row }) => <PatientNameAndId details={row?.original} />,
				size: patientColumnSize,
			};
		case "orderPatientNameAndId":
			return {
				header: ({ header }) => <TableTitle details={{ title: header.id, number: rowCount }} />,
				cell: ({ row }) => <PatientNameAndId details={row?.original} />,
				size: patientColumnSize,
			};
		case "startTime":
			return {
				cell: ({ row }) => `${timeFormat(localFromUTC(row?.original?.start))}`,
				size: 100,
			};
		case "endTime":
			return {
				cell: ({ row }) => `${timeFormat(localFromUTC(row?.original?.end))}`,
				size: 100,
			};
		case "startDate":
			return {
				cell: ({ row }) => `${shortDateFormat(localFromUTC(row?.original?.start))}`,
				size: 100,
			};
		case "endDate":
			return {
				cell: ({ row }) => `${shortDateFormat(localFromUTC(row?.original?.end))}`,
				size: 100,
			};
		case "startDateTime":
			return {
				cell: ({ row }) =>
					`${shortDateFormat(localFromUTC(row?.original?.start))}, ${timeFormat(localFromUTC(row?.original?.start))}`,
				size: 140,
			};
		case "endDateTime":
			return {
				cell: ({ row }) =>
					`${shortDateFormat(localFromUTC(row?.original?.end))}, ${timeFormat(localFromUTC(row?.original?.end))}`,
				size: 140,
			};
		case "duration":
			return {
				cell: ({ row }) => `${durationFromDates(localFromUTC(row?.original?.start), localFromUTC(row?.original?.end))}`,
				size: 120,
			};
		case "procedure":
			return {
				cell: ({ row }) => <TruncatedArray list={row?.original?.procedures} name="procedures" />,
				size: 280,
			};
		case "request":
			return {
				cell: ({ row }) => <ServiceRequests requests={row?.original?.orders} />,
				size: calculatedRequestsWidth > 480 ? calculatedRequestsWidth : 480,
			};
		case "isRead":
			return {
				cell: ({ row }) => <PatientSeenRequest orders={row?.original?.orders} allSeen={row?.original?.allSeen} />,
				size: 60,
			};
		case "results":
			return {
				cell: ({ row }) =>
					row?.original?.anyResults && (
						<Link
							text={"View"}
							onClick={(e) => {
								e.stopPropagation();
							}}
							to={resultCallback?.(row?.original)}
							icon
						/>
					),
				size: 80,
			};
		case "contactType":
			return {
				cell: ({ row }) => `${row?.original?.contactType ?? "Face to face"}`,
			};
		default:
			return {
				accessorFn: (row) => `${row[column.accessorKey] ?? "-"}`,
			};
	}
};
